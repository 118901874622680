import axios from "axios"
import {handleError} from "../../components/snackbar/reducer";

export const LOAD_REQUESTED = 'cup/LOAD_REQUESTED'
export const LOAD = 'cup/LOAD'
export const LOAD_CUP = 'cup/LOAD_CUP'

const initialState = {
    isLoading: true,
    cups: [],
    cup: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_REQUESTED:
            return {
                ...state,
                isLoading: true
            }
        case LOAD:
            return {
                ...state,
                isLoading: false,
                cups: action.payload
            }
        case LOAD_CUP:
            return {
                ...state,
                isLoading: false,
                cup: action.payload
            }
        default:
            return state
    }
}

export const load = () => {
    return async dispatch => {
        dispatch({
            type: LOAD_REQUESTED
        })
        try {
            const res = await axios.get("/api/cup/list")
            dispatch({
                type: LOAD,
                payload: res.data,
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const loadCup = (cupId) => {
    return async dispatch => {
        dispatch({
            type: LOAD_REQUESTED
        })
        try {
            const res = await axios.get("/api/cup/" + cupId)
            dispatch({
                type: LOAD_CUP,
                payload: res.data,
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}
