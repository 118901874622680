import axios from 'axios';
import { handleError, QUEUE_MESSAGE } from '../../components/snackbar/reducer';

export const LOAD_REQUESTED = 'admin/LOAD_REQUESTED';
export const LOAD = 'admin/LOAD';
export const LOAD_DISABLED_TEAMS = 'admin/LOAD_DISABLED_TEAMS';
export const LOAD_TEAMS = 'admin/LOAD_TEAMS';
export const LOAD_GROUPS = 'admin/LOAD_GROUPS';
export const LOAD_STATISTICS = 'admin/LOAD_STATISTICS';
export const LOAD_CUPS = 'admin/LOAD_CUPS';
export const LOAD_CUP = 'admin/LOAD_CUP';
export const LOAD_CUP_REQUESTED = 'admin/LOAD_CUP_REQUESTED';

const initialState = {
    users: [],
    allUsers: [],
    allTeams: [],
    allGroups: [],
    isLoading: true,
    isLoadingCup: true,
    teams: [],
    cups: [],
    cup: {},
    botStats: {},
    disabledChatTeams: [],
    ratingLast30Days: [],
    recordingSessionsLast30Days: [],
    recordingWatchSessionsLast30Days: [],
    recordingWatchTimeLast30Days: [],
    maxConcurrentRecordingsLast30Days: [],
    notRatedLast30Days: [],
    matchesLast30DaysCancelled: [],
    teamsLast30Days: [],
    matchesLast30Days: [],
    searchesLast30Days: [],
    offersLast30Days: [],
    offersWithdrawnLast30Days: [],
    offersAcceptedLast30Days: [],
    offersLostLast30Days: [],
    usersLast30Days: [],
    revenues: {},
    activeMonthlyRevenue: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_REQUESTED:
            return {
                ...state,
                isLoading: true,
            };
        case LOAD:
            return {
                ...state,
                isLoading: false,
                users: action.payload.Users,
                userCount: action.payload.UserCount,
                teams: action.payload.Teams,
                teamCount: action.payload.TeamCount,
                botStats: action.payload.BotStats,
                allUsers: action.payload.AllUsers,
            };
        case LOAD_TEAMS:
            return {
                ...state,
                isLoading: false,
                allTeams: action.payload,
            };
        case LOAD_GROUPS:
            return {
                ...state,
                isLoading: false,
                allGroups: action.payload,
            };
        case LOAD_STATISTICS:
            return {
                ...state,
                isLoading: false,
                ratingLast30Days: action.payload.RatingLast30Days,
                recordingSessionsLast30Days: action.payload.RecordingSessionsLast30Days,
                recordingWatchSessionsLast30Days: action.payload.RecordingWatchSessionsLast30Days,
                recordingWatchTimeLast30Days: action.payload.RecordingWatchTimeLast30Days,
                maxConcurrentRecordingsLast30Days: action.payload.MaxConcurrentRecordingsLast30Days,
                notRatedLast30Days: action.payload.NotRatedLast30Days,
                matchesLast30DaysCancelled: action.payload.CancelledMatchesLast30Days,
                teamsLast30Days: action.payload.TeamsLast30Days,
                matchesLast30Days: action.payload.MatchesLast30Days,
                searchesLast30Days: action.payload.SearchesLast30Days,
                offersLast30Days: action.payload.OffersLast30Days,
                offersWithdrawnLast30Days: action.payload.OffersWithdrawnLast30Days,
                offersAcceptedLast30Days: action.payload.OffersAcceptedLast30Days,
                offersLostLast30Days: action.payload.OffersLostLast30Days,
                usersLast30Days: action.payload.UsersLast30Days,
                revenues: action.payload.Revenue,
                activeMonthlyRevenue: action.payload.MonthlyRecurringRevenue,
            };
        case LOAD_DISABLED_TEAMS:
            return {
                ...state,
                isLoading: false,
                disabledChatTeams: action.payload,
            };
        case LOAD_CUP_REQUESTED:
            return {
                ...state,
                isLoadingCup: true,
            };
        case LOAD_CUPS:
            return {
                ...state,
                isLoading: false,
                cups: action.payload,
            };
        case LOAD_CUP:
            return {
                ...state,
                isLoadingCup: false,
                cup: action.payload,
            };
        default:
            return state;
    }
};

export const load = () => {
    return async (dispatch) => {
        dispatch({
            type: LOAD_REQUESTED,
        });

        try {
            const res = await axios.get('/api/admin');
            dispatch({
                type: LOAD,
                payload: res.data,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const loadTeams = () => {
    return async (dispatch) => {
        dispatch({
            type: LOAD_REQUESTED,
        });
        try {
            const res = await axios.get('/api/admin/teams');
            dispatch({
                type: LOAD_TEAMS,
                payload: res.data,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const loadGroups = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOAD_REQUESTED,
            });
            const res = await axios.get('/api/admin/groups');
            dispatch({
                type: LOAD_GROUPS,
                payload: res.data,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const loadStatistics = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOAD_REQUESTED,
            });
            const res = await axios.get('/api/admin/statistics');
            dispatch({
                type: LOAD_STATISTICS,
                payload: res.data,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const loadCups = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOAD_REQUESTED,
            });
            const res = await axios.get('/api/admin/cups');
            dispatch({
                type: LOAD_CUPS,
                payload: res.data,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const loadDisabledTeams = () => {
    return async (dispatch) => {
        dispatch({
            type: LOAD_REQUESTED,
        });
        try {
            const res = await axios.get('/api/admin/chats/blocked');
            dispatch({
                type: LOAD_DISABLED_TEAMS,
                payload: res.data,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const enableTeamChatFunction = (teamID) => {
    return async (dispatch) => {
        try {
            const res = await axios.post('/api/admin/chats/blocked/enable', {
                TeamID: teamID,
            });
            dispatch({
                type: LOAD_DISABLED_TEAMS,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Chat function has been enabled',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const removeGameServer = (teamID) => {
    return async (dispatch) => {
        try {
            await axios.delete('/api/admin/teams/remove/gameserver', {
                data: {
                    TeamID: teamID,
                },
            });
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Game-Server removed.',
            });
            return true;
        } catch (e) {
            handleError(e);
            return false;
        }
    };
};

export const setLockDuration = (teamID, lockTime, lockReason, lockTeam) => {
    return async (dispatch) => {
        try {
            await axios.post('/api/admin/teams/lock', {
                TeamID: teamID,
                LockDate: lockTime,
                LockReason: lockReason,
            });
            if (lockTeam) {
                dispatch({
                    type: QUEUE_MESSAGE,
                    variant: 'success',
                    msg: 'Team has been locked.',
                });
            } else {
                dispatch({
                    type: QUEUE_MESSAGE,
                    variant: 'success',
                    msg: 'Team has been unlocked.',
                });
            }
        } catch (e) {
            handleError(e);
        }
    };
};

export const removeTeamMember = (teamID, steamAccountID) => {
    return async (dispatch) => {
        try {
            const res = await axios.delete('/api/admin/teams/remove/member', {
                data: {
                    TeamID: teamID,
                    SteamAccountID: steamAccountID,
                },
                params: {
                    teamID: teamID,
                },
            });
            console.log(res.data);
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Member has been removed from Team.',
            });

            return res.data;
        } catch (e) {
            handleError(e);
            return null;
        }
    };
};

export const removeTeamGroup = (teamID, groupID) => {
    return async (dispatch) => {
        try {
            const res = await axios.delete('/api/admin/teams/remove/group', {
                data: {
                    TeamID: teamID,
                    GroupID: groupID,
                },
                params: {
                    teamID: teamID,
                },
            });
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Team has been removed from Group.',
            });
            return res.data;
        } catch (e) {
            handleError(e);
            return null;
        }
    };
};

export const removeTeam = (teamID) => {
    return async (dispatch) => {
        try {
            const res = await axios.delete('/api/admin/teams/delete', {
                data: {
                    TeamID: teamID,
                },
            });
            return res.data;
        } catch (e) {
            handleError(e)(dispatch);
            return null;
        }
    };
};

export const removeTeamFromGroup = (teamID, groupID) => {
    return async (dispatch) => {
        try {
            const res = await axios.delete('/api/admin/groups/delete/team', {
                data: {
                    TeamID: teamID,
                    GroupID: groupID,
                },
            });
            return res.data;
        } catch (e) {
            handleError(e)(dispatch);
            return null;
        }
    };
};

export const resendEmailConfirmation = (userID) => {
    return async (dispatch) => {
        try {
            await axios.post('/api/admin/users/detail/resend-confirmation', {
                UserID: userID,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Confirmation E-Mail resend.',
            });
        } catch (e) {
            handleError(e);
        }
    };
};

export const saveCup = (
    name,
    start,
    pausesPerTeam,
    pauseTime,
    knife,
    record,
    overtime,
    damageprint,
    epfCup,
    maxTeams,
    type,
    gameID
) => {
    return async (dispatch) => {
        try {
            const res = await axios.post('/api/admin/cup', {
                Name: name,
                StartDate: start,
                Type: type,
                GameID: gameID,
                CupSettings: {
                    MaxTeams: parseInt(maxTeams, 10),
                    PausesPerTeam: parseInt(pausesPerTeam, 10),
                    PauseTimer: parseInt(pauseTime, 10),
                    Knife: knife,
                    Recording: record,
                    DamagePrint: damageprint,
                    OverTime: overtime,
                    EpfCup: epfCup,
                },
            });
            dispatch({
                type: LOAD_CUPS,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Cup has been added.',
            });
            return true;
        } catch (e) {
            handleError(e)(dispatch);
            return false;
        }
    };
};

export const saveTeamToCup = (cupId, teamId) => {
    return async (dispatch) => {
        try {
            const res = await axios.post('/api/admin/cup/saveteam', {
                CupID: cupId,
                TeamID: parseInt(teamId, 10),
            });
            dispatch({
                type: LOAD_CUPS,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Team has been added to Cup.',
            });
            return true;
        } catch (e) {
            handleError(e)(dispatch);
            return false;
        }
    };
};

export const saveCupMatch = (
    cupId,
    mapId,
    matchTime,
    teamLowerId,
    teamHigherId,
    bracketId,
    round,
    serverIP,
    serverPassword
) => {
    return async (dispatch) => {
        try {
            const res = await axios.post('/api/admin/cup/creatematch', {
                CupID: cupId,
                MapID: mapId,
                MatchTime: matchTime,
                TeamLower: teamLowerId,
                TeamHigher: teamHigherId,
                BracketType: bracketId,
                Round: parseInt(round, 10),
                Server: {
                    ServerIP: serverIP,
                    Password: serverPassword,
                },
            });
            dispatch({
                type: LOAD_CUPS,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Match has been created.',
            });
            return true;
        } catch (e) {
            handleError(e)(dispatch);
            return false;
        }
    };
};

export const loadCupInfo = (cupId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOAD_CUP_REQUESTED,
            });
            const res = await axios.get('/api/admin/cup/info', {
                params: {
                    cupId: cupId,
                },
            });
            dispatch({
                type: LOAD_CUP,
                payload: res.data,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const saveEditMatch = (
    cupID,
    cupMatchID,
    matchId,
    time,
    mapID,
    round,
    bracket,
    teamLower,
    teamHigher,
    scoreLower,
    scoreHigher,
    serverIP,
    serverPassword
) => {
    return async (dispatch) => {
        try {
            const res = await axios.post(
                '/api/admin/cup/edit/match',
                {
                    CupID: cupID,
                    CupMatchID: cupMatchID,
                    MatchID: matchId,
                    MatchTime: time,
                    MapID: mapID,
                    Round: round,
                    BracketType: bracket,
                    TeamLower: teamLower,
                    TeamHigher: teamHigher,
                    ScoreLower: parseInt(scoreLower, 10),
                    ScoreHigher: parseInt(scoreHigher, 10),
                    Server: {
                        ServerIP: serverIP,
                        Password: serverPassword,
                    },
                },
                {
                    params: {
                        cupId: cupID,
                    },
                }
            );
            dispatch({
                type: LOAD_CUP,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Match has been edit.',
            });
            return true;
        } catch (e) {
            handleError(e)(dispatch);
            return false;
        }
    };
};

export const deleteCupMatch = (cupMatchId, cupID) => {
    return async (dispatch) => {
        try {
            const res = await axios.delete('/api/admin/cup/delete/match', {
                params: {
                    cupId: cupID,
                },
                data: {
                    CupMatchID: cupMatchId,
                },
            });
            dispatch({
                type: LOAD_CUP,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Match has been deleted.',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const deleteCupTeam = (cupID, cupTeamID) => {
    return async (dispatch) => {
        try {
            const res = await axios.delete('/api/admin/cup/delete/team', {
                params: {
                    cupId: cupID,
                },
                data: {
                    CupTeamID: cupTeamID,
                },
            });
            dispatch({
                type: LOAD_CUP,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Team has been removed.',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const updateCupInfo = (
    cupID,
    name,
    start,
    pausesPerTeam,
    pauseTime,
    knife,
    record,
    overtime,
    damageprint,
    epfCup,
    maxTeams,
    type,
    gameID
) => {
    return async (dispatch) => {
        try {
            const res = await axios.post('/api/admin/cup/update', {
                CupID: cupID,
                Name: name,
                StartDate: start,
                Type: type,
                GameID: gameID,
                CupSettings: {
                    MaxTeams: parseInt(maxTeams, 10),
                    PausesPerTeam: parseInt(pausesPerTeam, 10),
                    PauseTimer: parseInt(pauseTime, 10),
                    Knife: knife,
                    Recording: record,
                    DamagePrint: damageprint,
                    OverTime: overtime,
                    EpfCup: epfCup,
                },
            });
            dispatch({
                type: LOAD_CUPS,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Cup has been updated.',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};
