import axios from 'axios';
import { tz } from 'moment-timezone';

import { QUEUE_MESSAGE } from 'components/snackbar/reducer';
import { handleError } from '../../components/snackbar/reducer';

export const LOAD = 'settings/LOAD';
export const UPDATE_NOTIFY_SETTINGS_PARTIALLY = 'settings/UPDATE_NOTIFY_SETTINGS_PARTIALLY';
export const UPDATE_SETTINGS = 'settings/UPDATE_SETTINGS';

const initialState = {
    isLoading: true,
    isSavingSettings: false,
    eMail: '',
    personaName: '',
    avatar: '',
    password: '',
    repeatedPassword: '',
    notificationSettings: null,
    isDeleting: false,
    isDeleted: false,
    profileId: 0,
    theme: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SETTINGS:
            return {
                ...state,
                ...action.newSettings,
                isDeleted: false,
            };

        case UPDATE_NOTIFY_SETTINGS_PARTIALLY:
            return {
                ...state,
                notificationSettings: {
                    ...state.notificationSettings,
                    ...action.data,
                },
            };

        case LOAD:
            return {
                ...state,
                isLoading: false,
                eMail: action.payload.EMail,
                notificationSettings: action.payload.Notifications,
                groups: action.payload.Groups,
                localTimezone: action.payload.LocalTimezone,
                timeFormat: action.payload.TimeFormat || 'eu',
                personaName: action.payload.PersonaName,
                avatar: action.payload.Avatar,
                profileId: action.payload.ID,
                theme: action.payload.Theme || 'dark',
            };

        default:
            return state;
    }
};

export const updateSettings = (newSettings) => {
    return (dispatch) =>
        dispatch({
            type: UPDATE_SETTINGS,
            newSettings,
        });
};

export const updateNotificationSettings = (newSettings) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_NOTIFY_SETTINGS_PARTIALLY,
            data: newSettings,
        });
    };
};

export const load = () => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_SETTINGS,
            newSettings: {
                isLoading: true,
            },
        });

        try {
            const res = await axios.get('/api/profile/settings');

            if (!res.data.LocalTimezone) {
                const resTimezone = await axios.get('/api/profile/timezone');
                const guessedTimezone = tz.guess();
                if (!resTimezone.data && guessedTimezone) {
                    res.data.LocalTimezone = guessedTimezone;
                } else {
                    res.data.LocalTimezone = resTimezone.data;
                }
            }

            dispatch({
                type: LOAD,
                payload: res.data,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const saveSettings = (sett) => {
    return async (dispatch) => {
        if (sett.password !== '' && sett.password !== sett.repeatedPassword) {
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Passwords do not match!',
                variant: 'error',
            });

            return;
        }

        dispatch({
            type: UPDATE_SETTINGS,
            newSettings: {
                isSavingSettings: true,
                isDeleted: false,
            },
        });

        try {
            const res = await axios.post('/api/profile/settings', {
                EMail: sett.eMail,
                NotificationSettings: sett.notificationSettings,
                LocalTimezone: sett.localTimezone,
                TimeFormat: sett.timeFormat,
                PersonaName: sett.personaName,
                Avatar: sett.avatar,
                NewPassword: sett.password,
                Theme: sett.theme,
            });
            dispatch({
                type: UPDATE_SETTINGS,
                newSettings: {
                    isSavingSettings: false,
                    password: '',
                    repeatedPassword: '',
                },
            });

            dispatch({
                type: LOAD,
                payload: res.data,
            });

            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Saved!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
            dispatch({
                type: UPDATE_SETTINGS,
                newSettings: {
                    isSavingSettings: false,
                },
            });
        }
    };
};

export const deleteUserAccount = () => {
    return async (dispatch) => {
        try {
            await axios.delete('/api/profile/delete-account');
            window.location = '/';
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'You account has been deleted.',
            });
            return true;
        } catch (e) {
            handleError(e)(dispatch);
            return false;
        }
    };
};
