import React from 'react';
import { Box, Button, FormControl, InputAdornment, Input, TextField, ButtonBase } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface CountInputFieldProps {
    value: number;
    min: number;
    max: number;
    onChange: (newNumber: number) => void;
    disabled?: boolean;
    sx?: SxProps<Theme>;
}

function CountInputField({ disabled, value, min, max, onChange, sx = {} }: CountInputFieldProps) {
    return (
        <TextField
            variant="outlined"
            size="small"
            value={value}
            disabled={disabled}
            onChange={(e) => {
                const parsedValue = parseInt(e.target.value, 10);
                if (isNaN(parsedValue)) {
                    return;
                }
                if (parsedValue < min) {
                    onChange(min);
                } else if (parsedValue > max) {
                    onChange(max);
                } else {
                    onChange(parsedValue);
                }
            }}
            InputProps={{
                inputProps: {
                    style: {
                        width: 30,
                        textAlign: 'center',
                        marginRight: 10,
                    },
                },
                startAdornment: (
                    <InputAdornment position="start">
                        <ButtonBase
                            disabled={disabled}
                            sx={{
                                backgroundColor: 'action.hover',
                                marginLeft: -1.6,
                                padding: 1,
                                borderRadius: 1,
                                color: disabled ? 'text.disabled' : 'text',
                                '&:hover': { backgroundColor: 'action.disabled' },
                            }}
                            onClick={() => {
                                if (value > min) {
                                    onChange(value - 1);
                                }
                            }}
                        >
                            <Remove />
                        </ButtonBase>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="start">
                        <ButtonBase
                            disabled={disabled}
                            sx={{
                                backgroundColor: 'action.hover',
                                marginRight: -2.6,
                                padding: 1,
                                borderRadius: 1,
                                color: disabled ? 'text.disabled' : 'text',
                                '&:hover': { backgroundColor: 'action.disabled' },
                            }}
                            onClick={() => {
                                if (value < max) {
                                    onChange(value + 1);
                                }
                            }}
                        >
                            <Add />
                        </ButtonBase>
                    </InputAdornment>
                ),
            }}
            sx={sx}
        />
    );
}

export default CountInputField;
