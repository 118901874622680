import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface BoostLinearProgressProps {
    value: number;
    max: number;
    nextLevel: number;
    sx?: SxProps<Theme>;
}
function BoostLinearProgress({ value, max, nextLevel, sx = {} }: BoostLinearProgressProps) {
    return (
        <Typography
            component="div"
            variant="caption"
            sx={{
                padding: 0.5,
                borderWidth: 1,
                borderColor: grey[800],
                borderStyle: 'solid',
                textTransform: 'uppercase',
                backgroundColor: grey[600],
                position: 'relative',
                textAlign: 'center',
                color: 'primary.contrastText',
                ...sx,
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: `${(value / max) * 100}%`,
                    backgroundColor: 'primary.main',
                }}
            />
            <Box sx={{ position: 'relative', zIndex: 1 }}>
                {value >= max ? (
                    `level ${nextLevel + 1} unlocked`
                ) : (
                    <>
                        <strong>
                            {value}/{max} boosts
                        </strong>{' '}
                        to level {nextLevel + 1}
                    </>
                )}
            </Box>
        </Typography>
    );
}

export default BoostLinearProgress;
