import React from 'react';
import { Product } from '../../model/products';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1.5),
        backgroundColor: theme.palette.primary.light,
        borderRadius: 100,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

interface ProductIconProps {
    product: Product;
}

function ProductIcon({ product }: ProductIconProps) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <product.icon size={20} color="white" />
        </div>
    );
}

export default ProductIcon;
