import axios from 'axios'
import {handleError} from "../../components/snackbar/reducer";

export const LOAD_REQUESTED = 'stats/LOAD_REQUESTED'
export const LOAD = 'stats/LOAD'
export const LOAD_STAT = 'stats/LOAD_STAT'
export const LOAD_MAP = 'stats/LOAD_MAP'

const initialState = {
    isLoading: true,
    stats: [],
    CT: [],
    TERRORIST: [],
    game: {},
}

let unsortedCTTeam = [];
let unsortedTTeam = [];
let game;

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_REQUESTED:
            return {
                ...state,
                isLoading: true
            }
        case LOAD:
            return {
                ...state,
                isLoading: false,
                stats: action.payload
            }
        case LOAD_STAT:
            unsortedCTTeam = [];
            unsortedTTeam = [];
            game = action.payload
            for (let j in action.payload.PlayerStats) {
                if (game.PlayerStats[j].Team === "CT") {
                    unsortedCTTeam.push(game.PlayerStats[j]);
                } else if (game.PlayerStats[j].Team === "TERRORIST") {
                    unsortedTTeam.push(game.PlayerStats[j]);
                }
            }
            return {
                ...state,
                CT: unsortedCTTeam,
                TERRORIST: unsortedTTeam,
                isLoading: false,
                game: game,
            }
        case LOAD_MAP:
            let overAllPlayer = []
            for (let j in action.payload.OverAllPlayerStats) {
                overAllPlayer.push(action.payload.OverAllPlayerStats[j])
            }
            return {
                ...state,
                stats: action.payload,
                overAllPlayer: overAllPlayer,
                isLoading: false,
            }
        default:
            return state
    }
}

export const load = () => {
    return async dispatch => {
        dispatch({
            type: LOAD_REQUESTED
        })
        try {
            const res = await axios.get("/api/stats")
            dispatch({
                type: LOAD,
                payload: res.data
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const loadStats = (id) => {
    return async dispatch => {
        dispatch({
            type: LOAD_REQUESTED
        })
        try {
            const res = await axios.get("/api/stats/" + id)
            dispatch({
                type: LOAD_STAT,
                payload: res.data
            })
        } catch (e) {
            handleError(e)
        }
    }
}

export const loadStatsMap = (map) => {
    return async dispatch => {
        dispatch({
            type: LOAD_REQUESTED
        })
        try {
            const res = await axios.get("/api/stats/map/" + map)
            dispatch({
                type: LOAD_MAP,
                payload: res.data
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}
