import { combineReducers } from 'redux'

import login from 'containers/login/reducer'
import qrCode from 'containers/qr-code/reducer'
import app from 'containers/app/reducer'
import team from 'containers/team/reducer'
import invite from 'containers/invite/reducer'
import matches from 'containers/matches/reducer'
import matchChat from 'containers/matches/reducerChat'
import admin from 'containers/admin/reducer'
import groups from 'containers/groups/reducer'
import snack from 'components/snackbar/reducer'
import settings from 'containers/settings/reducer'
import confirmEmail from 'containers/confirm-email/reducer'
import search from 'containers/search/reducer'
import rating from 'containers/rating/reducer'
import strats from 'containers/team/strats/reducer'
import stats from 'containers/stats/reducer'
import cup from 'containers/cup/reducer'

export default combineReducers({
    snack,
    login,
    qrCode,
    confirmEmail,
    team,
    admin,
    groups,
    invite,
    matches,
    settings,
    matchChat,
    search,
    app,
    rating,
    strats,
    stats,
    cup,
})
