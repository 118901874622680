export const SUBSCRIBE = 'matchChat/SUBSCRIBE'
export const ADD_MESSAGES = 'matchChat/ADD_MESSAGES'
export const UNSUBSCRIBE = 'matchChat/UNSUBSCRIBE'


const initialState = {}

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_MESSAGES:
            const newMessages = []
            if (!action.reset) {
                newMessages.push(...state[action.matchId].messages)
            }
            newMessages.push(action.message)

            return {
                ...state,
                [action.matchId]: {
                    ...state[action.matchId],
                    isLoading: false,
                    messages: newMessages
                }
            }
        case SUBSCRIBE:
            return {
                ...state,
                [action.matchId]: {
                    isLoading: false,
                    messages: []
                }
            }
        case UNSUBSCRIBE:
            return {
                ...state,
                [action.matchId]: {
                    isLoading: false,
                    messages: []
                }
            }
        default:
            return state
    }
}

export const addMessages = (matchId, message, reset = false) => {
    return dispatch => {
        dispatch({
            type: ADD_MESSAGES,
            matchId,
            message,
            reset,
        })
    }
}

export const subscribe = matchId => {
    return dispatch => {
        dispatch({
            type: SUBSCRIBE,
            matchId
        })
    }
}

export const unsubscribe = matchId => {
    return dispatch => {
        dispatch({
            type: UNSUBSCRIBE,
            matchId
        })
    }
}

