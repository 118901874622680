import axios from "axios"
import {handleError, QUEUE_MESSAGE} from "../../components/snackbar/reducer";

export const LOAD_REQUESTED = 'rating/LOAD_REQUESTED'
export const LOAD = 'rating/LOAD'

const initialState = {
    isLoading: true,
    rating: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_REQUESTED:
            return {
                ...state,
                isLoading: true
            }
        case LOAD:
            return {
                ...state,
                isLoading: false,
                rating: action.payload
            }
        default:
            return state
    }
}
export const load = () => {
    return async dispatch => {
        dispatch({
            type: LOAD_REQUESTED
        })
        try {
            const res = await axios.get("api/rating")
            dispatch({
                type: LOAD,
                payload: res.data
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const loadRate = () => {
    return async dispatch => {
        dispatch({
            type: LOAD_REQUESTED
        })
        try {
            const res = await axios.get("/api/rating/notrated")
            dispatch({
                type: LOAD,
                payload: res.data
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const postRating = (id, rating, reasons, comment) => {
    return async dispatch => {
        if (rating === null || rating === undefined) {
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Please enter a rate',
                variant: 'warning'
            })
            return
        }
        if (rating <= 2 && (reasons === undefined || reasons.length === 0)) {
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Please select a reason',
                variant: 'warning'
            })
            return
        }
        try {
            const res = await axios.post("/api/rating/rate", {
                ID: id,
                Rating: rating,
                RatingReason: reasons,
                Comment: comment,
            })
            dispatch({
                type: LOAD,
                payload: res.data
            })
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Success',
                variant: 'success'
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const deleteEntryRating = (ratingId) => {
    return async dispatch => {
        try {
            const res = await axios.delete("/api/rating/remove", {
                data: {
                    ID: ratingId,
                }
            })
            dispatch({
                type: LOAD,
                payload: res.data
            })
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Entry removed',
                variant: 'success'
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}
