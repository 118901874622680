import {handleError, QUEUE_MESSAGE} from "../../../components/snackbar/reducer";
import axios from "axios";
import {getMapFromNumber} from "../../../utils/maps";

export const LOAD_REQUESTED = 'strats/LOAD_REQUESTED'
export const LOAD = 'strats/LOAD'
export const LOAD_STRATIGIES_MAP = 'strats/LOAD_STRATIGIES_MAP'
export const LOAD_GRENADES = 'strats/LOAD_GRENADES'
export const LOAD_GRENADES_MAP = 'strats/LOAD_GRENADES_MAP'
export const LOAD_NOTES = 'strats/LOAD_NOTES'
export const SEARCH_STRATEGY = 'strat/SEARCH_STRATEGY'
export const SEARCH_NOTE = 'strat/SEARCH_NOTE'

const initialState = {
    isLoading: false,
    strategies: {
        TeamStrategies: [],
        TeamGrenades: [],
    },
    grenades: {
        Grenades: [],
    },
    notes: {
        Notes: [],
        FilteredNote: []
    },
    search: '',
    searchGrenade: '',
    searchNote: '',
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_REQUESTED:
            return {
                ...state,
                isLoading: true,
            }
        case LOAD:
            return {
                ...state,
                strategies: {
                    TeamStrategies: action.payload.Strategies,
                    TeamGrenades: action.payload.TeamGrenades,
                },
                isLoading: false,
            }
        case LOAD_STRATIGIES_MAP:
            return {
                ...state,
                strategies: {
                    TeamStrategies: action.payload.Strategies,
                    TeamGrenades: action.payload.TeamGrenades,
                },
                isLoading: false,
            }
        case LOAD_GRENADES:
            return {
                ...state,
                isLoading: false,
                grenades: {
                    Grenades: action.payload.Grenades
                }
            }
        case LOAD_GRENADES_MAP:
            return {
                ...state,
                isLoading: false,
                grenades: {
                    Grenades: action.payload.Grenades
                }
            }
        case LOAD_NOTES:
            return {
                ...state,
                isLoading: false,
                notes: {
                    FilteredNote: action.payload,
                    Notes: action.payload,
                }
            }
        case SEARCH_NOTE:
            let newNoteState = Object.assign({}, state);
            const searchNote = action.search
            const gameIdNote = action.gameId
            if (searchNote !== '') {
                let filteredNotes = state.notes.Notes.filter(note => {
                    return note.SteamAccount.PersonaName.toLowerCase().includes(searchNote.toLowerCase())
                })
                newNoteState.notes.FilteredNote = filteredNotes
                newNoteState.searchNote = searchNote
            } else {
                newNoteState.notes.FilteredNote = newNoteState.notes.Notes
                newNoteState.searchNote = ''
            }
            return newNoteState
        default:
            return state
    }
}

export const loadStrategies = () => {
    return async dispatch => {
        dispatch({
            type: LOAD_REQUESTED,
        })
        try {
            const res = await axios.get("/api/team/strategies")
            dispatch({
                type: LOAD,
                payload: res.data
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const loadStrategyMap = (mapId, archive) => {
    return async dispatch => {
        dispatch({
            type: LOAD_REQUESTED,
        })
        try {
            const res = await axios.get("/api/team/strategies/map", {
                params: {
                    mapId: mapId,
                    archive: archive
                }
            })
            dispatch({
                type: LOAD_STRATIGIES_MAP,
                payload: res.data
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const loadGrenades = () => {
    return async dispatch => {
        dispatch({
            type: LOAD_REQUESTED,
        })
        try {
            const res = await axios.get("/api/team/grenades")
            dispatch({
                type: LOAD_GRENADES,
                payload: res.data
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const loadGrenadeMap = (mapId, archive) => {
    return async dispatch => {
        dispatch({
            type: LOAD_REQUESTED,
        })
        try {
            const res = await axios.get('/api/team/grenades/map', {
                params: {
                    mapId: mapId,
                    archive: archive
                }
            })
            dispatch({
                type: LOAD_GRENADES_MAP,
                payload: res.data
            })
        } catch (e) {
            handleError(e)
        }
    }
}

export const loadNotes = () => {
    return async dispatch => {
        dispatch({
            type: LOAD_REQUESTED,
        })
        try {
            const res = await axios.get('/api/team/notes')
            dispatch({
                type: LOAD_NOTES,
                payload: res.data
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const saveStrat = (name, map, playerStarts, selectedPlayers, mapId, team, bombsiteArea) => {
    return async dispatch => {
        try {
            const res = await axios.post('/api/team/addstrategy', {
                Name: name,
                MapID: map,
                PlayerStrats: playerStarts,
                SelectedPlayer: selectedPlayers,
                TeamSiteID: team,
                BombsiteArea: bombsiteArea,
            }, {
                params: {
                    mapId: mapId,
                }
            })
            dispatch({
                type: LOAD,
                payload: res.data
            })
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Strategy has been saved'
            })

            return true;
        } catch (e) {
            handleError(e)(dispatch)
            return false;
        }
    }
}

export const saveGrenade = (name, map, grenadeType, croppedImage) => {
    return async dispatch => {
        try {
            const res = await axios.post("/api/team/addgrenade", {
                Name: name,
                MapID: map,
                GrenadeID: grenadeType,
                CroppedImages: croppedImage
            })
            dispatch({
                type: LOAD_GRENADES,
                payload: res.data
            })
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Grenade has been saved'
            })

            return true;
        } catch (e) {
            handleError(e)(dispatch)
            return false;
        }
    }
}

export const saveNote = (userId, mapId, note, title) => {
    return async dispatch => {
        try {
            const res = await axios.post("/api/team/addnote", {
                SteamAccountID: userId,
                MapID: mapId,
                Note: note,
                Title: title,
            })
            dispatch({
                type: LOAD_NOTES,
                payload: res.data
            })
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Note has been saved'
            })
            return true;
        } catch (e) {
            handleError(e)(dispatch)
            return false;
        }
    }
}

export const updateStrategy = (id, name, map, playerStarts, selectedPlayers, mapId, team, bombsiteArea) => {
    return async dispatch => {
        try {
            const res = await axios.post("/api/team/updatestrategy", {
                ID: id,
                Name: name,
                MapID: map,
                PlayerStrats: playerStarts,
                SelectedPlayer: selectedPlayers,
                TeamSiteID: team,
                BombsiteArea: bombsiteArea,
            }, {
                params: {
                    mapId: mapId
                }
            })
            dispatch({
                type: LOAD,
                payload: res.data
            })
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Strategy has been updated'
            })
            return true;
        } catch (e) {
            handleError(e)(dispatch)
            return false;
        }
    }
}

export const archiveStrat = (ID, mapId) => {
    return async dispatch => {
        try {
            const res = await axios.post("/api/team/archivestrategy", {
                ID: ID
            }, {
                params:{
                    mapId: mapId,
                }
            })
            dispatch({
                type: LOAD,
                payload: res.data
            })
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Strategy has been archived'
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const deleteArchivedStrategy = (ID) => {
    return async dispatch => {
        try {
            const res = await axios.delete("/api/team/deletearchivedstrategy", {
                data: {
                    ID: ID
                },
                params:{
                    archive: true
                }
            })
            dispatch({
                type: LOAD,
                payload: res.data
            })
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Strategy has been deleted'
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const activateStrategy = (ID) => {
    return async dispatch => {
        try {
            const res = await axios.post("/api/team/activatestrategy", {
                ID: ID
            }, {
                params:{
                    archive: true
                }
            })
            dispatch({
                type: LOAD,
                payload: res.data
            })
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Strategy has been reactivated'
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const archiveGrenade = (ID, mapId) => {
    return async dispatch => {
        try {
            const res = await axios.post("/api/team/archivegrenade", {
                ID: ID
            },{
                params:{
                    mapId: mapId,
                }
            })
            dispatch({
                type: LOAD_GRENADES,
                payload: res.data
            })
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Grenade has been archived'
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const activateGrenade = (ID) => {
    return async dispatch => {
        try {
            const res = await axios.post("/api/team/activategrenade", {
                ID: ID
            }, {
                params:{
                    archive: true
                }
            })
            dispatch({
                type: LOAD_GRENADES,
                payload: res.data
            })
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Grenade has been reactivated'
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const deleteArchivedGrenade = (ID) => {
    return async dispatch => {
        try {
            const res = await axios.delete("/api/team/deletearchivedgrenade", {
                data: {
                    ID: ID
                },
                params:{
                    archive: true
                }
            })
            dispatch({
                type: LOAD_GRENADES,
                payload: res.data
            })
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Grenade has been deleted'
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const updateNote = (detailID, title, mapID, note, playerID) => {
    return async dispatch => {
        try {
            const res = await axios.post("/api/team/updatenote", {
                DetailID: detailID,
                Title: title,
                MapID: mapID,
                Note: note,
                SteamAccountID: playerID
            })
            dispatch({
                type: LOAD_NOTES,
                payload: res.data
            })
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Note has been updated'
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const deleteNote = (detailID) => {
    return async dispatch => {
        try {
            const res = await axios.delete("/api/team/deletenote", {
                data: {
                    DetailID: detailID
                }
            })
            dispatch({
                type: LOAD_NOTES,
                payload: res.data
            })
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Note has been deleted'
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const searchNote = (search) => {
    return dispatch => {
        dispatch({
            type: SEARCH_NOTE,
            search: search,
        })
    }
}
