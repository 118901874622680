import React from 'react';
import { Button, ButtonProps, Dialog, DialogContent, DialogTitle } from '@mui/material';
import BraintreeDropIn from './braintree-drop-in';
import { PraccDialog } from '../../components/layout/dialogs';

interface BraintreePaymentMethodsButtonProps extends ButtonProps {
    entity: string;
    onChange?: () => void;
}
function BraintreePaymentMethodsButton({
    entity,
    children,
    onChange = () => {},
    ...rest
}: BraintreePaymentMethodsButtonProps) {
    const [isOpen, setOpen] = React.useState(false);

    return (
        <>
            <Button {...rest} onClick={() => setOpen(true)}>
                {children}
            </Button>
            <PraccDialog open={isOpen} onClose={() => setOpen(false)} fullWidth maxWidth="md">
                <DialogTitle>Payment Methods</DialogTitle>
                <DialogContent>
                    <BraintreeDropIn
                        entity={entity}
                        onCompleted={() => {
                            setOpen(false);
                            onChange();
                        }}
                    />
                </DialogContent>
            </PraccDialog>
        </>
    );
}

export default BraintreePaymentMethodsButton;
