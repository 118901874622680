import { styled } from '@mui/material/styles';
import { Dialog, emphasize } from '@mui/material';

export const PraccDialog = styled(Dialog)(({ theme }) => ({
    '& > .MuiPaper-root': {
        position: 'relative',
        overflow: 'hidden',
        '::after': {
            position: 'absolute',
            zIndex: 0,
            content: '""',
            top: '-30%',
            left: 0,
            width: '100%',
            height: '150%',
            backgroundImage: 'radial-gradient(ellipse farthest-side, rgba(0, 111, 255, 0.05), transparent)',
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        },
        '> *': {
            zIndex: 1,
        },
    },
    '& .MuiDialogTitle-root': {
        background: `linear-gradient(45deg, ${emphasize(theme.palette.background.paper, 0.08)} 0%, ${emphasize(
            theme.palette.background.paper,
            0.1
        )} 10%, ${emphasize(theme.palette.background.paper, 0.12)} 15%, ${emphasize(
            theme.palette.background.paper,
            0.08
        )} 75%, ${emphasize(theme.palette.background.paper, 0.07)} 100%)`,
        borderLeft: `5px solid ${theme.palette.secondary.main}`,
        borderBottom: `1px solid ${emphasize(theme.palette.background.paper, 0.03)}`,
        marginBottom: theme.spacing(2),
        boxShadow: `0px 1px 10px ${emphasize(theme.palette.background.paper, 0.03)}`,
    },
}));
