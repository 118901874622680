import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PraccDialog } from '../../components/layout/dialogs';
import { hideRatingDetails } from './reducer';
import {
    Alert,
    Avatar,
    Box,
    Button,
    Chip,
    CircularProgress,
    DialogContent,
    DialogTitle,
    IconButton,
    Rating,
    Tooltip,
    Typography,
} from '@mui/material';
import axios from 'axios';
import { handleError } from '../../components/snackbar/reducer';
import RatingSummary, { RatingDetails } from '../../components/team/rating-summary';
import ClearIcon from '@mui/icons-material/Clear';
// @ts-ignore
import TimeAgo from 'timeago-react';
import { getReasonFromNumber } from '../../utils/reasons';
import BlockTeamButton from '../../components/team/BlockTeamButton';
import { ProfileTeam } from '../../model/profile';
import FileComplaintDialog from './file-complaint-dialog';

interface RatingDetailsDialogData {
    open: boolean;
    teamId: number;
}

interface RatingTeam {
    ID: number;
    Name: string;
    Logo: string;
}

interface CommentTeam {
    ID: number;
    Name: string;
    Logo: string;
}

export interface Comment {
    ID: number;
    CreatedAt: string;
    Rating: number;
    ReportReason: Array<number> | null;
    Comment: string;
    FromTeam: CommentTeam;
}

function RatingDetailsDialog() {
    const details = useSelector((state: any) => state.app.ratingDetailsDialog) as RatingDetailsDialogData;
    const dispatch = useDispatch();
    const [team, setTeam] = React.useState<RatingTeam | null>(null);
    const [ratings, setRatings] = React.useState<RatingDetails | null>(null);
    const [comments, setComments] = React.useState<Array<Comment>>([]);
    const actingTeam = useSelector((state: any) => state.app.profile?.Team) as ProfileTeam;
    const [disputedComment, setDisputedComment] = React.useState<Comment | null>(null);
    const [contentViolation, setContentViolation] = React.useState(false);
    const [showDispute, setShowDispute] = React.useState(false);

    React.useEffect(() => {
        setTeam(null);
        setRatings(null);
        setComments([]);
        setDisputedComment(null);
        setShowDispute(false);
    }, [details.teamId]);

    React.useEffect(() => {
        if (!details.open || !details.teamId) {
            return;
        }

        async function load() {
            try {
                const [teamRes, ratingRes, commentRes] = await Promise.all([
                    axios.get(`/api/team/view/${details.teamId}`),
                    axios.get(`/api/teams/${details.teamId}/ratings`),
                    axios.get(`/api/teams/${details.teamId}/ratings/comments`),
                ]);
                setTeam(teamRes.data);
                setRatings(ratingRes.data);
                setComments(commentRes.data.Comments);
            } catch (e) {
                handleError(e)(dispatch);
            }
        }

        load();
    }, [details]);

    if (!details.open && !details.teamId) {
        return null;
    }

    if (!team || !ratings) {
        return (
            <PraccDialog open={details.open}>
                <CircularProgress size={60} sx={{ margin: 3 }} />
            </PraccDialog>
        );
    }

    return (
        <>
            <PraccDialog open={details.open} onClose={() => hideRatingDetails()(dispatch)} maxWidth="md" fullWidth>
                <DialogTitle
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        Standing of <Avatar src={team.Logo} sx={{ marginLeft: 1, marginRight: 1 }} /> {team.Name}
                    </Box>
                    <IconButton onClick={() => hideRatingDetails()(dispatch)}>
                        <ClearIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', position: 'relative' }}
                >
                    <Box sx={{ position: 'sticky', top: 0 }}>
                        <Box sx={{ backgroundColor: 'action.hover', padding: 2, borderRadius: 3 }}>
                            <RatingSummary details={ratings} />
                        </Box>
                        <Box sx={{ marginTop: 2 }}>
                            <BlockTeamButton fullWidth team={team} />
                        </Box>
                    </Box>
                    <Box sx={{ marginLeft: 2 }}>
                        {!comments && <Alert severity="info">No other team left a comment for this team, yet.</Alert>}

                        {comments.map((c) => {
                            return (
                                <Box key={c.ID} sx={{ marginBottom: 2 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Avatar src={c.FromTeam.Logo} sx={{ marginRight: 1, width: 30, height: 30 }} />
                                        <Box component="span" sx={{ fontWeight: 'bold' }}>
                                            {c.FromTeam.Name}
                                        </Box>

                                        <Typography
                                            variant="caption"
                                            component="div"
                                            color="textSecondary"
                                            sx={{
                                                marginLeft: 1,
                                            }}
                                        >
                                            <TimeAgo datetime={c.CreatedAt} />
                                        </Typography>

                                        {details.teamId === actingTeam?.ID && (
                                            <>
                                                <Tooltip
                                                    title="You can file a complaint if you think this comment was made in bad faith, and does not reflect what actually happened."
                                                    arrow
                                                    placement="top"
                                                >
                                                    <Button
                                                        size="small"
                                                        sx={{ marginLeft: 2 }}
                                                        onClick={() => {
                                                            setShowDispute(true);
                                                            setContentViolation(false);
                                                            setDisputedComment(c);
                                                        }}
                                                    >
                                                        Dispute Comment
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip
                                                    title="You can report this comment if it contains harmful content such as hate speech or similar. The check is performed by an external service."
                                                    arrow
                                                    placement="top"
                                                >
                                                    <Button
                                                        size="small"
                                                        sx={{ marginLeft: 2 }}
                                                        onClick={() => {
                                                            setShowDispute(true);
                                                            setContentViolation(true);
                                                            setDisputedComment(c);
                                                        }}
                                                    >
                                                        Report Comment
                                                    </Button>
                                                </Tooltip>
                                            </>
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            marginTop: 0.2,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        <Rating value={c.Rating} max={5} size="small" readOnly />

                                        {c.ReportReason?.map((reason) => {
                                            return (
                                                <Chip
                                                    sx={{ marginLeft: 0.5, marginTop: 0.5 }}
                                                    size="small"
                                                    label={getReasonFromNumber(1, reason).label}
                                                    key={reason}
                                                />
                                            );
                                        })}
                                    </Box>

                                    <Typography component="div" sx={{ padding: 1 }}>
                                        {c.Comment}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Box>
                </DialogContent>
            </PraccDialog>

            {disputedComment && (
                <FileComplaintDialog
                    comment={disputedComment}
                    contentViolation={contentViolation}
                    open={showDispute}
                    onClose={() => setShowDispute(false)}
                    onFiled={() => {
                        setShowDispute(false);
                        setComments(comments.filter((c) => c.ID !== disputedComment?.ID));
                    }}
                />
            )}
        </>
    );
}

export default RatingDetailsDialog;
