import { createTheme, emphasize } from '@mui/material/styles';
import { indigo, pink } from '@mui/material/colors';

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: indigo,
        secondary: pink,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: (theme) => `
                ::-webkit-scrollbar {
                    height: 12px;
                    width: 12px;
                    background: transparent;
                }
                
                ::-webkit-scrollbar-thumb {
                    background: ${emphasize(theme.palette.background.paper, 0.4)};
                    -webkit-border-radius: 1ex;
                    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
                }
                
                ::-webkit-scrollbar-corner {
                    background: ${emphasize(theme.palette.background.paper, 0.2)};
                }
            `,
        },
    },
});

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#59a6ff',
        },
        secondary: {
            main: '#F50057',
        },
        background: {
            paper: 'rgb(12, 17, 38)',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: (theme) => `
                ::-webkit-scrollbar {
                    height: 12px;
                    width: 12px;
                    background: transparent;
                }
                
                ::-webkit-scrollbar-thumb {
                    background: ${emphasize(theme.palette.background.paper, 0.4)};
                    -webkit-border-radius: 1ex;
                    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
                }
                
                ::-webkit-scrollbar-corner {
                    background: ${emphasize(theme.palette.background.paper, 0.2)};
                }
            `,
        },
    },
});
