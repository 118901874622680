import axios from 'axios';
import { QUEUE_MESSAGE } from 'components/snackbar/reducer';
import { handleError } from '../../components/snackbar/reducer';
import { SAVE_TEAM, SAVE_TEAM_REQUESTED } from '../team/reducer';
import { browserHistory } from '../../store';

export const SET_GROUP_NAME = 'groups/SET_GROUP_NAME';
export const SET_GROUP_TYPE = 'groups/SET_GROUP_TYPE';
export const SET_GROUP_DESCRIPTION = 'groups/SET_GROUP_DESCRIPTION';
export const SAVE_GROUP_REQUESTED = 'groups/SAVE_GROUP_REQUESTED';
export const SAVE_GROUP = 'groups/SAVE_GROUP';
export const LOAD_REQUESTED = 'groups/LOAD_REQUESTED';
export const LOAD = 'groups/LOAD';
export const LOAD_ADMIN = 'groups/LOAD_ADMIN';
export const LOAD_ADMIN_TEAM = 'groups/LOAD_ADMIN_TEAM';
export const LOAD_ADMIN_TEAM_DETAIL = 'groups/LOAD_ADMIN_TEAM_DETAIL';
export const LOAD_ADMIN_TEAM_LOG = 'groups/LOAD_ADMIN_TEAM_LOG';
export const LOAD_ADMIN_LOGS = 'groups/LOAD_ADMIN_LOGS';
export const LOAD_ADMIN_APPLICATIONS = 'groups/LOAD_ADMIN_APPLICATIONS';
export const LOAD_ADMIN_STATISTICS = 'groups/LOAD_ADMIN_STATISTICS';
export const SET_UPLOADED_IMAGE = 'groups/SET_UPLOADED_IMAGE';
export const SET_CROPPED_IMAGE = 'groups/SET_CROPPED_IMAGE';
export const REMOVE_IMAGE = 'groups/REMOVE_IMAGE';
export const REQUEST_REMOVE = 'groups/REQUEST_REMOVE';
export const REMOVE = 'groups/REMOVE';
export const CHANGE_POSITION = 'groups/CHANGE_POSITION';
export const SAVE_MEMBERS_REQUESTED = 'groups/SAVE_MEMBERS_REQUESTED';
export const SAVE_MEMBERS = 'groups/SAVE_MEMBERS';
export const SET_INVITE_LINK = 'groups/SET_INVITE_LINK';
export const SET_INVITE_LINK_REQUESTED = 'groups/SET_INVITE_LINK_REQUESTED';
export const SET_INVITE_ADMIN_LINK = 'team/SET_INVITE_ADMIN_LINK';
export const SET_INVITE_ADMIN_LINK_REQUESTED = 'team/SET_INVITE_ADMIN_LINK_REQUESTED';
export const REQUEST_REMOVE_TEAM = 'groups/REQUEST_REMOVE_TEAM';
export const REMOVE_TEAM = 'groups/REMOVE_TEAM';
export const SAVE_TEAMS_REQUESTED = 'groups/SAVE_TEAMS_REQUESTED';
export const SAVE_TEAMS = 'groups/SAVE_TEAMS';
export const GROUP_LEAVE_REQUESTED = 'groups/GROUP_LEAVE_REQUESTED';
export const LOAD_APPLICATIONS_REQUESTED = 'groups/LOAD_APPLICATIONS_REQUESTED';
export const LOAD_APPLICATIONS = 'groups/LOAD_APPLICATIONS';
export const SAVE_APPLICATIONS_REQUESTED = 'groups/SAVE_APPLICATIONS_REQUESTED';
export const SAVE_APPLICATIONS = 'groups/SAVE_APPLICATIONS';
export const REQUEST_DECLINE = 'groups/REQUEST_DECLINE';
export const SET_COOLDOWN_TYPE = 'groups/SET_COOLDOWN_TYPE';
export const SET_DECLINE_NOTE = 'groups/SET_DECLINE_NOTE';
export const DECLINE = 'groups/DECLINE';
export const ACCEPT = 'groups/ACCEPT';
export const RESET = 'groups/RESET';
const initialState = {
    groupList: [],
    isLoading: true,
    adminList: [],
    groupName: '',
    logoUrl: null,
    croppedImage: null,
    groupType: 0,
    members: [],
    teams: {
        Data: [],
        Page: 0,
        PerPage: 25,
        TotalCount: 0,
        HasMore: false,
    },
    teamCount: 0,
    inviteId: '',
    inviteAdminId: '',
    description: '',
    applicationText: '',
    applications: [],
    usersLast30Days: [],
    teamsLast30Days: [],
    matchesLast30Days: [],
    searchesLast30Days: [],
    offersLast30Days: [],
    ratingsLast30Days: [],
    groupLogs: [],
    teamIsPartOfGroup: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_GROUP_NAME:
            return {
                ...state,
                groupName: action.groupName,
            };
        case SET_GROUP_TYPE:
            return {
                ...state,
                groupType: action.groupType,
            };
        case SET_GROUP_DESCRIPTION:
            return {
                ...state,
                description: action.description,
            };
        case SAVE_GROUP_REQUESTED:
            return {
                ...state,
                isSavingGroup: true,
            };
        case SAVE_GROUP:
            return {
                ...state,
                isSavingGroup: false,
            };
        case LOAD_REQUESTED:
            return {
                ...state,
                isLoading: true,
            };
        case RESET:
            return initialState;
        case LOAD:
            if (action.payload.Name) {
                return {
                    ...state,
                    isLoading: false,
                    groupName: action.payload.Name,
                    description: action.payload.Description,
                    groupType: action.payload.Type,
                    members: action.payload.Members,
                    teams: action.payload.Teams,
                    teamCount: action.payload.TeamCount,
                    logoUrl: action.payload.Logo,
                    hasTeamAppliedToGroup: action.payload.TeamHasAppliedToGroup,
                    teamIsPartOfGroup: action.payload.TeamIsPartOfGroup,
                    originalGroup: action.payload,
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    groupList: action.payload.GroupList,
                    adminList: action.payload.AdminList,
                };
            }
        case LOAD_ADMIN:
            return {
                ...state,
                isLoading: false,
                groupName: action.payload.Name,
                description: action.payload.Description,
                groupType: action.payload.Type,
                members: action.payload.Members,
                logoUrl: action.payload.Logo,
                inviteId: action.payload.InviteId,
                inviteAdminId: action.payload.InviteAdminId,
                groupLogs: action.payload.Logs,
            };
        case LOAD_ADMIN_TEAM:
            return {
                ...state,
                isLoading: false,
                teams: action.payload,
            };
        case LOAD_ADMIN_LOGS:
            return {
                ...state,
                isLoading: false,
                groupLogs: action.payload.Logs,
            };
        case LOAD_ADMIN_APPLICATIONS:
            return {
                ...state,
                isLoading: false,
                applications: action.payload.Applications,
            };
        case LOAD_ADMIN_STATISTICS:
            return {
                ...state,
                isLoading: false,
                offersLast30Days: action.payload.OffersLast30Days,
                ratingsLast30Days: action.payload.RatingsLast30Days,
                matchesLast30Days: action.payload.MatchesLast30Days,
                searchesLast30Days: action.payload.SearchesLast30Days,
                teamsLast30Days: action.payload.TeamsLast30Days,
            };
        case LOAD_APPLICATIONS_REQUESTED:
            return {
                ...state,
                isLoading: true,
            };
        case LOAD_APPLICATIONS:
            return {
                ...state,
                isLoading: false,
                groupName: action.payload.GroupName,
                applications: action.payload.Applications,
            };
        case SET_UPLOADED_IMAGE:
            return {
                ...state,
                uploadedImage: action.image,
            };
        case SET_CROPPED_IMAGE:
            return {
                ...state,
                croppedImage: action.image,
            };
        case REMOVE_IMAGE:
            return {
                ...state,
                logoUrl: '',
                croppedImage: '',
            };
        case SET_INVITE_LINK_REQUESTED:
            return {
                ...state,
                isSavingMembers: true,
            };
        case SET_INVITE_LINK:
            return {
                ...state,
                isSavingMembers: false,
                inviteId: action.inviteId,
            };
        case SET_INVITE_ADMIN_LINK_REQUESTED:
            return {
                ...state,
                isSavingTeams: true,
            };
        case SET_INVITE_ADMIN_LINK:
            return {
                ...state,
                isSavingTeams: false,
                inviteAdminId: action.inviteAdminId,
            };
        case CHANGE_POSITION:
            return {
                ...state,
                members: state.members.map((m) => {
                    if (m.ID !== action.ID) {
                        return m;
                    }

                    return {
                        ...m,
                        Position: action.Position,
                    };
                }),
            };
        case REMOVE:
            return {
                ...state,
                members: state.members.map((m) => {
                    if (m.ID !== action.ID) {
                        return m;
                    }

                    return {
                        ...m,
                        isDeleted: true,
                        isDeleting: false,
                    };
                }),
            };
        case REQUEST_REMOVE:
            return {
                ...state,
                members: state.members.map((m) => {
                    if (m.ID !== action.ID) {
                        return m;
                    }

                    return {
                        ...m,
                        isDeleting: action.isDeleting,
                    };
                }),
            };
        case DECLINE:
            return {
                ...state,
                applications: state.applications.map((m) => {
                    if (m.ID !== action.ID) {
                        return m;
                    }

                    return {
                        ...m,
                        isDeclined: true,
                        isDeclining: false,
                    };
                }),
            };
        case REQUEST_DECLINE:
            return {
                ...state,
                applications: state.applications.map((m) => {
                    if (m.ID !== action.ID) {
                        return m;
                    }

                    return {
                        ...m,
                        cooldownType: m.cooldownType ? m.cooldownType : 1,
                        isDeclining: action.isDeclining,
                    };
                }),
            };

        case SET_DECLINE_NOTE:
            return {
                ...state,
                applications: state.applications.map((m) => {
                    if (m.ID !== action.ID) {
                        return m;
                    }

                    return {
                        ...m,
                        declineNote: action.declineNote,
                    };
                }),
            };

        case SET_COOLDOWN_TYPE:
            return {
                ...state,
                applications: state.applications.map((m) => {
                    if (m.ID !== action.ID) {
                        return m;
                    }

                    return {
                        ...m,
                        cooldownType: action.cooldownType,
                    };
                }),
            };

        case ACCEPT:
            return {
                ...state,
                applications: state.applications.map((m) => {
                    if (m.ID !== action.ID) {
                        return m;
                    }

                    return {
                        ...m,
                        isAccepted: true,
                    };
                }),
            };
        case REMOVE_TEAM:
            return {
                ...state,
                teams: state.teams.map((m) => {
                    if (m.ID !== action.ID) {
                        return m;
                    }

                    return {
                        ...m,
                        isDeleted: true,
                        isDeleting: false,
                    };
                }),
            };
        case REQUEST_REMOVE_TEAM:
            return {
                ...state,
                teams: state.teams.map((m) => {
                    if (m.ID !== action.ID) {
                        return m;
                    }

                    return {
                        ...m,
                        isDeleting: action.isDeleting,
                    };
                }),
            };
        case SAVE_TEAMS_REQUESTED:
            return {
                ...state,
                isSavingTeams: true,
            };
        case SAVE_TEAMS:
            return {
                ...state,
                isSavingTeams: false,
            };
        case SAVE_MEMBERS_REQUESTED:
            return {
                ...state,
                isSavingMembers: true,
            };
        case SAVE_MEMBERS:
            return {
                ...state,
                isSavingMembers: false,
            };
        case GROUP_LEAVE_REQUESTED:
            return {
                ...state,
                isLoading: true,
            };
        case LOAD_ADMIN_TEAM_DETAIL:
            return {
                ...state,
                teams: {
                    Data: state.teams.Data.map((m) => {
                        if (m.ID === action.teamId) {
                            return {
                                ...m,
                                Members: action.members,
                                RatingsGet: action.ratingsGet,
                            };
                        }
                        return m;
                    }),
                },
            };
        case LOAD_ADMIN_TEAM_LOG:
            return {
                ...state,
                teams: {
                    Data: state.teams.Data.map((m) => {
                        if (m.ID === action.teamId) {
                            return {
                                ...m,
                                Log: action.log,
                            };
                        }
                        return m;
                    }),
                },
            };
        default:
            return state;
    }
};

export const setGroupName = (groupName) => {
    return (dispatch) => {
        dispatch({
            type: SET_GROUP_NAME,
            groupName,
        });
    };
};

export const setGroupType = (groupType) => {
    return (dispatch) => {
        dispatch({
            type: SET_GROUP_TYPE,
            groupType,
        });
    };
};

export const setGroupDescription = (description) => {
    return (dispatch) => {
        dispatch({
            type: SET_GROUP_DESCRIPTION,
            description,
        });
    };
};

export const changePosition = (ID, Position, GroupId) => {
    return async (dispatch) => {
        dispatch({
            type: SAVE_MEMBERS_REQUESTED,
        });
        try {
            const res = await axios.post('/api/groups/' + GroupId + '/changeposition', {
                SteamAccountID: ID,
                Position: Position,
            });
            dispatch({
                type: SAVE_MEMBERS,
            });
            dispatch({
                type: LOAD,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Position change successful!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const requestRemove = (ID) => {
    return (dispatch) => {
        dispatch({
            type: REQUEST_REMOVE,
            isDeleting: true,
            ID,
        });
    };
};

export const remove = (ID) => {
    return (dispatch) => {
        dispatch({
            type: REMOVE,
            ID,
        });
        dispatch({
            type: QUEUE_MESSAGE,
            msg: 'Removed Admin!',
            variant: 'success',
        });
    };
};

export const keep = (ID) => {
    return (dispatch) => {
        dispatch({
            type: REQUEST_REMOVE,
            isDeleting: false,
            ID,
        });
    };
};

// team functions

export const requestRemoveTeam = (ID) => {
    return (dispatch) => {
        dispatch({
            type: REQUEST_REMOVE_TEAM,
            isDeleting: true,
            ID,
        });
    };
};

export const removeTeam = (ID) => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_TEAM,
            ID,
        });
        dispatch({
            type: QUEUE_MESSAGE,
            msg: 'Removed Team!',
            variant: 'success',
        });
    };
};

export const keepTeam = (ID) => {
    return (dispatch) => {
        dispatch({
            type: REQUEST_REMOVE_TEAM,
            isDeleting: false,
            ID,
        });
    };
};

export const generateNewInviteLink = (groupid) => {
    return async (dispatch) => {
        dispatch({
            type: SET_INVITE_LINK_REQUESTED,
        });

        try {
            const res = await axios.post('/api/groups/' + groupid + '/generate-invite-link');
            dispatch({
                type: LOAD_ADMIN,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Generated new invite Link!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const generateNewAdminInviteLink = (groupid) => {
    return async (dispatch) => {
        dispatch({
            type: SET_INVITE_ADMIN_LINK_REQUESTED,
        });

        try {
            const res = await axios.post('/api/groups/' + groupid + '/generate-admin-invite-link');
            dispatch({
                type: LOAD_ADMIN,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Generated new invite Link!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const saveNewGroup = (groups) => {
    return async (dispatch) => {
        dispatch({
            type: SAVE_GROUP_REQUESTED,
        });

        try {
            const res = await axios.post('/api/groups/create', {
                GroupName: groups.groupName,
                Type: groups.groupType,
                Description: groups.description,
                CroppedImage: groups.croppedImage,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'Your group was created successfully!',
            });
            browserHistory.push('/groups/' + res.data.ID);
        } catch (e) {
            handleError(e)(dispatch);
        } finally {
            dispatch({
                type: SAVE_GROUP,
            });
        }
    };
};

export const saveGroup = (groups) => {
    // HIER AUFGEHÖRT
    return async (dispatch) => {
        dispatch({
            type: SAVE_GROUP_REQUESTED,
        });

        try {
            const res = await axios.post('/api/groups/' + groups.groupId, {
                GroupName: groups.groupName,
                Type: groups.groupType,
                Description: groups.description,
                CroppedImage: groups.croppedImage,
                LogoUrl: groups.logoUrl,
            });
            dispatch({
                type: SAVE_GROUP,
            });
            dispatch({
                type: LOAD,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Saved Group!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        } finally {
            dispatch({
                type: SAVE_GROUP,
            });
        }
    };
};

export const deletingMember = (member) => {
    return async (dispatch) => {
        dispatch({
            type: SAVE_MEMBERS_REQUESTED,
        });
        try {
            const res = await axios.post('/api/groups/' + member.groupId + '/removemember', {
                ID: member.ID,
                SteamAccountID: member.ID,
            });
            dispatch({
                type: SAVE_MEMBERS,
            });
            dispatch({
                type: LOAD,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Removed Admin!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const loadAdmin = (groupid) => {
    return async (dispatch) => {
        dispatch({
            type: LOAD_REQUESTED,
        });

        try {
            const res = await axios.get('/api/groups/' + groupid + '/manage');
            dispatch({
                type: LOAD_ADMIN,
                payload: res.data,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const loadAdminTeams = (groupId, filter, page, rowsPerPage) => {
    return async (dispatch) => {
        dispatch({
            type: LOAD_REQUESTED,
        });

        try {
            const res = await axios.get('/api/groups/' + groupId + '/manage/teams', {
                params: {
                    query: filter,
                    page: page,
                    perPage: rowsPerPage,
                },
            });
            dispatch({
                type: LOAD_ADMIN_TEAM,
                payload: res.data,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const loadAdminLogs = (groupId) => {
    return async (dispatch) => {
        dispatch({
            type: LOAD_REQUESTED,
        });
        try {
            const res = await axios.get('/api/groups/' + groupId + '/manage/logs');
            dispatch({
                type: LOAD_ADMIN_LOGS,
                payload: res.data,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const loadAdminApplications = (groupId) => {
    return async (dispatch) => {
        dispatch({
            type: LOAD_APPLICATIONS_REQUESTED,
        });
        try {
            const res = await axios.get('/api/groups/' + groupId + '/applications');
            dispatch({
                type: LOAD_ADMIN_APPLICATIONS,
                payload: res.data,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const load = (groupid, admin) => {
    if (groupid && !admin) {
        return async (dispatch) => {
            dispatch({
                type: LOAD_REQUESTED,
            });

            try {
                const res = await axios.get('/api/groups/' + groupid);
                dispatch({
                    type: LOAD,
                    payload: res.data,
                });
            } catch (e) {
                handleError(e)(dispatch);
            }
        };
    } else if (groupid && admin) {
        return async (dispatch) => {
            dispatch({
                type: LOAD_REQUESTED,
            });

            try {
                const res = await axios.get('/api/groups/' + groupid + '/manage');
                dispatch({
                    type: LOAD,
                    payload: res.data,
                });
            } catch (e) {
                handleError(e)(dispatch);
            }
        };
    } else {
        return async (dispatch) => {
            dispatch({
                type: LOAD_REQUESTED,
            });

            try {
                const res = await axios.get('/api/groups');
                dispatch({
                    type: LOAD,
                    payload: res.data,
                });
            } catch (e) {
                handleError(e)(dispatch);
            }
        };
    }
};

export const setUploadedImage = (e) => {
    return (dispatch) => {
        e.preventDefault();
        const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        const reader = new FileReader();
        reader.onload = () => {
            dispatch({
                type: SET_UPLOADED_IMAGE,
                image: reader.result,
            });
        };
        reader.readAsDataURL(files[0]);
        e.target.value = '';
    };
};

export const confirmUploadedImage = (image) => {
    return (dispatch) => {
        dispatch({
            type: SET_CROPPED_IMAGE,
            image,
        });
    };
};

export const removeUploadedImage = () => {
    return (dispatch) => {
        dispatch({
            type: SET_UPLOADED_IMAGE,
            image: null,
        });
    };
};

export const removeImage = () => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_IMAGE,
        });
    };
};

export const leaveGroup = (groupId) => {
    return async (dispatch) => {
        dispatch({
            type: GROUP_LEAVE_REQUESTED,
        });

        try {
            await axios.post('/api/groups/leave', {
                ID: groupId,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'You successfully left the group!',
            });
            browserHistory.push('/groups');
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

// Application Management

export const loadApplications = (groupid) => {
    return async (dispatch) => {
        dispatch({
            type: LOAD_APPLICATIONS_REQUESTED,
        });

        try {
            const res = await axios.get('/api/groups/' + groupid + '/applications');
            dispatch({
                type: LOAD_APPLICATIONS,
                payload: res.data,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const saveApplication = (groupId, applicationId) => {
    return async (dispatch) => {
        dispatch({
            type: SAVE_APPLICATIONS_REQUESTED,
        });
        try {
            const res = await axios.post('/api/groups/' + groupId + '/acceptapplicaction', {
                ID: applicationId,
            });
            dispatch({
                type: SAVE_APPLICATIONS,
            });

            dispatch({
                type: LOAD_APPLICATIONS,
                payload: res.data,
            });

            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Saved Application!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const declineApplication = (groupId, applicationId, declineNote, cooldownType) => {
    return async (dispatch) => {
        dispatch({
            type: SAVE_APPLICATIONS_REQUESTED,
        });
        try {
            const res = await axios.post('/api/groups/' + groupId + '/declineapplication', {
                ID: applicationId,
                DeclineNote: declineNote,
                CooldownType: cooldownType,
            });
            dispatch({
                type: SAVE_APPLICATIONS,
            });

            dispatch({
                type: LOAD_APPLICATIONS,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Saved Application!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const removeTeamFromGroup = (groupId, teamId, kickReason) => {
    return async (dispatch) => {
        dispatch({
            type: SAVE_TEAM_REQUESTED,
        });
        try {
            const res = await axios.post('/api/groups/' + groupId + '/removeteam', {
                ID: teamId,
                KickReason: kickReason,
            });
            dispatch({
                type: SAVE_TEAM,
            });
            dispatch({
                type: LOAD_ADMIN_TEAM,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Team kicked!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const loadGroupTeamDetail = (groupId, teamId) => {
    return async (dispatch) => {
        try {
            const res = await axios.get('/api/groups/' + groupId + '/manage/teams/' + teamId + '/detail');
            dispatch({
                type: LOAD_ADMIN_TEAM_DETAIL,
                members: res.data.Members,
                ratingsGet: res.data.RatingsGet,
                teamId,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const loadAdminStatistics = (groupId) => {
    return async (dispatch) => {
        dispatch({
            type: LOAD_REQUESTED,
        });
        try {
            const res = await axios.get('/api/groups/' + groupId + '/manage/statistics');
            dispatch({
                type: LOAD_ADMIN_STATISTICS,
                payload: res.data,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const loadGroupTeamLogDetail = (groupId, teamId) => {
    return async (dispatch) => {
        try {
            const res = await axios.get('/api/groups/' + groupId + '/manage/teams/' + teamId + '/log');
            dispatch({
                type: LOAD_ADMIN_TEAM_LOG,
                log: res.data,
                teamId,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const reset = () => {
    return (dispatch) => dispatch({ type: RESET });
};
