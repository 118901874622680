import React from 'react';
import Link from '@mui/material/Link';
import { Box, Button, Popover, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { handleError, queueMessage } from '../snackbar/reducer';
import { useDispatch, useSelector } from 'react-redux';

function SetTradeUrlLink() {
    const [isOpen, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [isUpdating, setUpdating] = React.useState(false);
    const [tradeUrl, setTradeUrl] = React.useState('');
    const dispatch = useDispatch();

    async function handleUpdate() {
        setUpdating(true);
        try {
            await axios.put('/api/profile/accounts/steam/trade-token', {
                tradeUrl,
            });
            queueMessage('success', 'Your Trade-URL was updated successfully.')(dispatch);
            setOpen(false);
        } catch (e) {
            handleError(e)(dispatch);
        }
        setUpdating(false);
    }

    React.useEffect(() => {
        if (isOpen) {
            setTradeUrl('');
        }
    }, [isOpen]);

    return (
        <>
            <Popover open={isOpen} anchorEl={anchorEl} onClose={() => setOpen(false)}>
                <Box sx={{ padding: 2, display: 'flex', flexDirection: 'column', paddingTop: 1 }}>
                    <Typography variant="h6">Set Trade-URL</Typography>
                    <TextField
                        disabled={isUpdating}
                        sx={{ marginTop: 1 }}
                        variant="outlined"
                        size="small"
                        value={tradeUrl}
                        onChange={(e) => setTradeUrl(e.target.value)}
                        placeholder="https://steamcommunity.com/tradeoffer/new/?partner=123456&token=adefegd"
                        helperText={
                            <>
                                see{' '}
                                <Link
                                    href="https://steamcommunity.com/my/tradeoffers/privacy#trade_offer_access_url"
                                    target="_blank"
                                >
                                    https://steamcommunity.com/my/tradeoffers/privacy
                                </Link>
                            </>
                        }
                    />
                    <Button
                        disabled={isUpdating}
                        variant="contained"
                        color="primary"
                        sx={{ marginTop: 1 }}
                        onClick={handleUpdate}
                    >
                        Update Trade-URL
                    </Button>
                </Box>
            </Popover>
            <Link
                href="#set-trade-url"
                onClick={(e) => {
                    setOpen(true);
                    setAnchorEl(e.currentTarget);
                    e.preventDefault();
                }}
            >
                Change
            </Link>
        </>
    );
}

export default SetTradeUrlLink;
