import React from 'react';
import {makeStyles} from '@mui/styles';
import { Amount } from '../../model/products';

const useStyles = makeStyles((theme) => ({
    span: {
        whiteSpace: 'nowrap',
    },
}));

interface PlanAmountProps {
    amount: Amount;
    showPlus?: boolean;
}

function PlanAmount({ amount, showPlus }: PlanAmountProps) {
    const classes = useStyles();

    let currencySign = amount.currency;
    if (amount.currency === 'EUR') {
        currencySign = '€';
    } else if (amount.currency === 'USD') {
        currencySign = '$';
    }

    let text;
    if (amount.value % 100 !== 0 && amount.value % 10 === 0) {
        text = amount.value / 100 + '0 ' + currencySign;
    } else {
        text = amount.value / 100 + ' ' + currencySign;
    }

    if (showPlus && amount.value >= 0) {
        text = '+' + text;
    }

    return <span className={classes.span}>{text}</span>;
}

export default PlanAmount;
