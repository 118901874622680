import React from "react";
import {Paper, Button, CircularProgress} from '@mui/material'
import {makeStyles} from '@mui/styles';
import {logError} from "./snackbar/reducer";

const useStyles = makeStyles(theme => ({
    failedContainer: {
        flexGrow: 1,
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loadingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
    }
}))

function Loading(props) {
    const classes = useStyles()
    const {size = 60} = props

    if (props.error) {
        const eventId = logError(props.error)

        return <div className={classes.failedContainer}>
            <Paper className={classes.paper}>
                <div>
                    Some page assets failed to load (Event-ID {eventId}).
                </div>

                {props.retry && <div>
                    <Button onClick={props.retry} color="primary" variant="contained">Retry</Button>
                </div>}
            </Paper>
        </div>
    } else if (props.pastDelay) {
        return <div className={classes.loadingContainer}>
            <CircularProgress size={size} />
        </div>
    } else {
        return null;
    }
}

export default Loading