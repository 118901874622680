import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { green } from '@mui/material/colors';
import { Product } from '../../model/products';
import { OverridableStringUnion } from '@mui/types';
import { ButtonPropsColorOverrides } from '@mui/material/Button/Button';

interface UpgradeButtonProps extends ButtonProps {
    product: Product;
    isChangeScheduled: boolean;
    isDowngrade: boolean;
    isCancel: boolean;
}

function UpgradeButton({ product, isChangeScheduled, isDowngrade, isCancel, ...rest }: UpgradeButtonProps) {
    const [isHover, setHover] = React.useState(false);

    let color: OverridableStringUnion<
        'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
        ButtonPropsColorOverrides
    > = 'primary';
    if (isChangeScheduled) {
        // @ts-ignore
        if (isHover ^ isCancel) {
            color = 'error';
        } else {
            color = 'success';
        }
    }

    let buttonText = isChangeScheduled
        ? isHover
            ? isCancel
                ? 'Revert'
                : 'Remove'
            : isCancel
            ? 'Removed'
            : 'Added'
        : isDowngrade
        ? isCancel
            ? 'Cancel'
            : 'Downgrade'
        : 'Add';

    return (
        <Button
            color={color}
            variant={isDowngrade ? 'outlined' : 'contained'}
            onMouseEnter={() => isChangeScheduled && setHover(true)}
            onMouseLeave={() => setHover(false)}
            {...rest}
        >
            {buttonText}
        </Button>
    );
}

export default UpgradeButton;
