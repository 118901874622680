import React from 'react';
import Dialog from '@mui/material/Dialog';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import { PraccDialog } from '../../components/layout/dialogs';
import { Comment } from './rating-details-dialog';
import { handleError, queueMessage } from '../../components/snackbar/reducer';
import { useDispatch } from 'react-redux';
import axios from 'axios';

interface FileComplaintDialogProps extends DialogProps {
    comment: Comment;
    contentViolation: boolean;
    onClose: () => void;
    onFiled: () => void;
}

function FileComplaintDialog({ comment, contentViolation, onFiled, onClose, ...rest }: FileComplaintDialogProps) {
    const [isWorking, setWorking] = React.useState(false);
    const [text, setText] = React.useState('');
    const dispatch = useDispatch();

    React.useEffect(() => {
        setText('');
    }, [comment]);
    async function handleFiling() {
        const sendText = text.trim();
        if (sendText === '') {
            queueMessage('error', 'Please provide a reasoning why you think the comment should be removed.')(dispatch);
            return;
        }

        setWorking(true);
        try {
            const res = await axios.post('/api/complaints', {
                TargetTeamID: comment.FromTeam.ID,
                RatingID: comment.ID,
                ContentPolicyViolation: contentViolation,
                Message: {
                    Text: sendText,
                },
            });

            if (contentViolation) {
                if (res.data.Violation) {
                    queueMessage('success', 'The comment was externally reviewed, and has been removed now.');
                    onFiled();
                } else {
                    queueMessage(
                        'success',
                        'We had an external service review the comment. However, it currently does not qualify for removal. You can still file a dispute if you want a human to take a look at it.'
                    );
                    onClose?.();
                }
            } else {
                queueMessage('success', 'Your complaint was filed. The comment is hidden for the moment.')(dispatch);
                onFiled();
            }
        } catch (e) {
            handleError(e)(dispatch);
        }
        setWorking(false);
    }

    const title = contentViolation ? 'Report Content Violation' : 'File Complaint';

    return (
        <PraccDialog onClose={onClose} {...rest}>
            <DialogTitle>{title}</DialogTitle>

            <DialogContent>
                {contentViolation ? (
                    <DialogContentText>
                        <p>
                            If you feel like the comment contains harmful content such as hate speech, violence, etc. -
                            you can submit it for external review. The review is limited to the language that is used in
                            the comment.
                        </p>
                    </DialogContentText>
                ) : (
                    <>
                        <DialogContentText>
                            <p>
                                You can file a complaint if you believe a comment does not accurately reflect what
                                happened.
                            </p>
                            <p>
                                When you file a complaint, we will hide the disputed comment, and provide an opportunity
                                for the other side to present evidence to support their comment.
                            </p>
                            <p>
                                When the other team does not respond, their comment stays hidden. When they respond, an
                                admin will review the complaint, and make a determination if the comment stays hidden,
                                gets restored, or maybe some additional context will be displayed next to the comment.
                            </p>
                        </DialogContentText>
                        <Typography variant="subtitle2">Disputed Comment</Typography>
                        <Typography
                            sx={{ backgroundColor: 'rgba(0,0,0,0.2)', padding: 1, marginBottom: 1, fontSize: '80%' }}
                        >
                            {comment.Comment}
                        </Typography>

                        <TextField
                            variant="outlined"
                            multiline
                            fullWidth
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            rows={5}
                            placeholder="Let us know why you think the comment is not justified."
                        />
                    </>
                )}
            </DialogContent>

            <DialogActions>
                <Button variant="contained" color="primary" size="large" onClick={handleFiling} disabled={isWorking}>
                    {title}
                </Button>
            </DialogActions>
        </PraccDialog>
    );
}

export default FileComplaintDialog;
