import React from 'react';
import { Box, CircularProgress } from '@mui/material';

interface BoostLevelProgressProps {
    level: number;
    maxLevel: number;
}

function BoostLevelProgress({ level, maxLevel }: BoostLevelProgressProps) {
    return (
        <Box sx={{ position: 'relative', fontSize: 0 }}>
            <CircularProgress
                variant="determinate"
                value={100}
                size={50}
                sx={{ position: 'absolute', top: 0, left: 0, color: 'grey' }}
            />
            <CircularProgress variant="determinate" value={(level / maxLevel) * 100} color="primary" size={50} />
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 14,
                    fontWeight: 'bold',
                }}
            >
                Lvl {level}
            </Box>
        </Box>
    );
}

export default BoostLevelProgress;
