import lolDefaultMapImage from '../containers/app/img/maps/summoners-rift.jpg';
import lolWildRiftDefaulMapImage from '../containers/app/img/maps/lol-wild-rift.jpg';
import csgoCT from '../containers/app/img/teams/CT.png';
import csgoT from '../containers/app/img/teams/T.png';
import rainbowAttacker from '../containers/app/img/teams/rainbow_attacker.svg';
import rainbowDefender from '../containers/app/img/teams/rainbow_defender.svg';

export const GAME_ID_CSGO = 1;
export const GAME_ID_LOL = 2;
export const GAME_ID_DOTA2 = 3;
export const GAME_ID_RB6 = 4;
export const GAME_ID_VALORANT = 5;
export const GAME_ID_LOL_WILD_RIFT = 6;
export const GAME_ID_HALO_INFINITE = 7;
export const GAME_ID_ROCKET_LEAGUE = 8;
export const GAME_ID_DEADLOCK = 9;
export const GAME_ID_SPECTRE_DIVIDE = 10;

export const GAME_LOGO_CSGO = require('../containers/play/assets/cs2.png');
export const GAME_LOGO_LOL =
    'https://lp-assets.pracc.com/home/2b99d4a3a65cee1a19a2d455f500b55c6e218665/images/logo-lol.svg';
export const GAME_LOGO_DOTA2 =
    'https://lp-assets.pracc.com/home/2b99d4a3a65cee1a19a2d455f500b55c6e218665/images/logo-dota.svg';
export const GAME_LOGO_RB6 =
    'https://lp-assets.pracc.com/home/2b99d4a3a65cee1a19a2d455f500b55c6e218665/images/logo-rainbow-six.svg';
export const GAME_LOGO_VALORANT =
    'https://lp-assets.pracc.com/home/2b99d4a3a65cee1a19a2d455f500b55c6e218665/images/logo-valorant.svg';
export const GAME_LOGO_LOL_WILD_RIFT =
    'https://upload.wikimedia.org/wikipedia/commons/4/42/League_of_Legends_Wild_Rift_logo.png';
export const GAME_LOGO_HALO_INFINITE = require('../assets/games/ludwig-gaias-halo-infinite-v2-par-playbox.jpg');
export const GAME_LOGO_ROCKET_LEAGUE =
    'https://lp-assets.pracc.com/home/2b99d4a3a65cee1a19a2d455f500b55c6e218665/images/logo-rocket-league.svg';
export const GAME_LOGO_DEADLOCK = require('../assets/games/deadlock.png');
export const GAME_LOGO_SPECTRE_DIVIDE = require('../assets/games/spectre.png');

export interface UserGame {
    id: number;
    key: string;
    label: string;
    abbr: string;
    gameCounts: number[];
    rank?: string;
    teams?: Array<any>;
    logo: string;
    defaultMapImage?: string;
}

const games: UserGame[] = [
    {
        id: GAME_ID_CSGO,
        key: 'csgo',
        label: 'Counter-Strike 2',
        abbr: 'CS2',
        gameCounts: [1],
        rank: 'Faceit',
        teams: [
            {
                key: 0,
                abbr: 'CT',
                label: 'Counter-Terrorist',
                image: csgoCT,
            },
            {
                key: 1,
                abbr: 'T',
                label: 'Terrorist',
                image: csgoT,
            },
        ],
        logo: GAME_LOGO_CSGO,
    },
    {
        id: GAME_ID_LOL,
        key: 'lol',
        label: 'League of Legends',
        abbr: 'LoL',
        gameCounts: [1, 2, 3, 5],
        defaultMapImage: lolDefaultMapImage,
        rank: 'League',
        logo: GAME_LOGO_LOL,
    },
    {
        id: GAME_ID_DOTA2,
        key: 'dota2',
        label: 'Dota 2',
        abbr: 'Dota2',
        gameCounts: [1, 2, 3, 5],
        rank: 'Dota2',
        logo: GAME_LOGO_DOTA2,
    },
    {
        id: GAME_ID_RB6,
        key: 'rb6',
        label: 'Rainbow Six',
        abbr: 'RB6',
        gameCounts: [1],
        teams: [
            {
                key: 0,
                abbr: 'D',
                label: 'Defender',
                image: rainbowDefender,
            },
            {
                key: 1,
                abbr: 'A',
                label: 'Attacker',
                image: rainbowAttacker,
            },
        ],
        logo: GAME_LOGO_RB6,
    },
    {
        id: GAME_ID_VALORANT,
        key: 'valorant',
        label: 'Valorant',
        abbr: 'Valorant',
        gameCounts: [1],
        rank: 'Valorant',
        teams: [
            {
                key: 0,
                abbr: 'D',
                label: 'Defender',
                image: null,
            },
            {
                key: 1,
                abbr: 'A',
                label: 'Attacker',
                image: null,
            },
        ],
        logo: GAME_LOGO_VALORANT,
    },
    {
        id: GAME_ID_LOL_WILD_RIFT,
        key: 'lol_wild_rift',
        label: 'League of Legends: Wild Rift',
        abbr: 'LoL: Wild Rift',
        gameCounts: [1, 2, 3, 5],
        defaultMapImage: lolWildRiftDefaulMapImage,
        logo: GAME_LOGO_LOL_WILD_RIFT,
    },
    {
        id: GAME_ID_HALO_INFINITE,
        key: 'halo_infinite',
        label: 'Halo Infinite',
        abbr: 'Halo Infinite',
        gameCounts: [4],
        logo: GAME_LOGO_HALO_INFINITE,
    },
    {
        id: GAME_ID_ROCKET_LEAGUE,
        key: 'rocket_league',
        label: 'Rocket League',
        abbr: 'Rocket League',
        gameCounts: [5],
        logo: GAME_LOGO_ROCKET_LEAGUE,
    },
    {
        id: GAME_ID_DEADLOCK,
        key: 'deadlock',
        label: 'Deadlock',
        abbr: 'Deadlock',
        gameCounts: [1, 2, 3, 5],
        logo: GAME_LOGO_DEADLOCK,
    },
    {
        id: GAME_ID_SPECTRE_DIVIDE,
        key: 'spectre_divide',
        label: 'Spectre Divide',
        abbr: 'Spectre Divide',
        gameCounts: [1],
        logo: GAME_LOGO_SPECTRE_DIVIDE,
    },
];

export function getGameForProfile(profile: any) {
    const gameId = profile && profile.Team ? profile.Team.GameID : 0;

    return getGameForId(gameId);
}

export function getGameForId(gameId: number) {
    for (const game of games) {
        if (game.id === gameId) {
            return game;
        }
    }

    return {
        id: gameId,
        key: 'unknown',
        label: 'Game #' + gameId,
        abbr: 'Game # ' + gameId,
        gameCounts: [],
        rank: 'unknown',
        defaultMapImage: undefined,
    };
}

export function hasGameTeams(gameId: number) {
    for (const game of games) {
        if (gameId === game.id && game.teams) {
            return true;
        }
    }
    return false;
}

export function getTeamFromNumber(gameId: number, teamId: number) {
    if (gameId !== undefined && teamId !== null) {
        for (const game of games) {
            if (game.id === gameId && game.teams) {
                return game.teams.find((team: any) => team.key === teamId);
            }
        }
    }

    return null;
}

export default games;
