import axios from 'axios'
import {handleError} from "../../components/snackbar/reducer";

export const RELOAD_QR_CODE = 'qrcode/RELOAD';
export const UPDATE_QR_CODE = 'qrcode/UPDATE';
export const UPDATE_QR_CODE_LIFETIME = 'qrcode/UPDATE_LIFETIME';
export const RELOAD_QR_CODE_COMPLETED = 'qrcode/RELOAD_COMPLETED';

const QR_CODE_LIFETIME = 30 * 1000; // We could also send this from the server.

const initialState = {
    isLoading: true,
    qrCodeValue: 'empty',
    qrCodeLoaded: 0,
    qrCodeLifetime: 0,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case RELOAD_QR_CODE:
            return {
                ...state,
                isLoading: true,
            }

        case RELOAD_QR_CODE_COMPLETED:
            return {
                ...state,
                isLoading: false,
            }

        case UPDATE_QR_CODE:
            return {
                ...state,
                qrCodeValue: 'pracc-mobile-auth:' + JSON.stringify(action.payload),
                qrCodeExpires: action.payload.Expires,
                qrCodeLifetime: 0,
                qrCodeLoaded: new Date().getTime(),
            }

        case UPDATE_QR_CODE_LIFETIME:
            return {
                ...state,
                qrCodeLifetime: Math.min(100, (new Date().getTime() - state.qrCodeLoaded) / QR_CODE_LIFETIME * 100),
            }

        default:
            return state
    }
}

export const reloadQrCode = () => {
    return async dispatch => {
        dispatch({
            type: RELOAD_QR_CODE
        })

        try {
            const res = await axios.get("/api/mobile-app/qr-auth-code")
            dispatch({
                type: UPDATE_QR_CODE,
                payload: res.data,
            })
        } catch (e) {
            handleError(e)(dispatch)
        } finally {
            dispatch({
                type: RELOAD_QR_CODE_COMPLETED,
            })
        }
    }
}

export const updateLifetime = () => {
    return async dispatch => {
        dispatch({
            type: UPDATE_QR_CODE_LIFETIME,
        })
    }
}