import moment from 'moment-timezone'

export const SERVER_TIMEZONE = 'Europe/Berlin'

export function getTimezoneForProfile(profile) {
    if (!profile || !profile.Settings || !profile.Settings.LocalTimezone) {
        return moment.tz.guess()
    }

    return profile.Settings.LocalTimezone
}