import axios from 'axios'
import {handleError} from "../../components/snackbar/reducer";

const initialState = {}

export default (state = initialState, action) => {
    switch (action.type) {
        default:
            return state
    }
}

export const logout = () => {
    return async dispatch => {
        try {
            await axios.post('/api/logout')
            window.location = '/'
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}
