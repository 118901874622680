import React, {FunctionComponent} from 'react';
import PaypalSmartButton from './paypal-smart-button';
import { CircularProgress, Typography } from '@mui/material';
import {withStyles} from '@mui/styles';
import axios from 'axios';
import { getErrorMessage } from '../../components/snackbar/reducer';
import { AiOutlineCheckCircle as ConfirmedIcon } from 'react-icons/ai';
import { green } from '@mui/material/colors';
import { PendingPaymentRequest } from '../../model/subscription';

const styles = (theme: any) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&>div': {
            flexGrow: 1,
        },
        minHeight: 100,
    },

    loadingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 100,
    },
});

interface PaymentRequestState {
    isConfirming: boolean;
    isConfirmed: boolean;
    error: string | null;
}

interface PaymentRequestProps {
    paymentRequest: PendingPaymentRequest;
    onConfirmed: () => void;
}

interface CombinedProps extends PaymentRequestProps {
    classes: Record<string, string>;
}

class PaymentRequest extends React.Component<CombinedProps, PaymentRequestState> {
    state: PaymentRequestState = {
        isConfirming: false,
        isConfirmed: false,
        error: null,
    };

    async confirmApproval(subscriptionId: any) {
        const { paymentRequest, onConfirmed } = this.props;

        this.setState({ isConfirming: true });

        try {
            await axios.put(`/api/payments/paypal/confirm`, {
                planId: paymentRequest.PaypalPlanId,
                subscriptionId,
            });
            onConfirmed();
            this.setState({ isConfirmed: true });
        } catch (e) {
            this.setState({ error: getErrorMessage(e) });
        } finally {
            this.setState({ isConfirming: false });
        }
    }

    render() {
        const { paymentRequest, classes } = this.props;

        return (
            <div className={classes.container}>
                {this.state.isConfirming ? (
                    <div className={classes.loadingContainer}>
                        <CircularProgress size={60} />
                    </div>
                ) : this.state.isConfirmed ? (
                    <ConfirmedIcon color={green[400]} size={60} />
                ) : this.state.error ? (
                    <Typography color="error">{this.state.error}</Typography>
                ) : (
                    <PaypalSmartButton planId={paymentRequest.PaypalPlanId} onApprove={this.onPaypalApprove} />
                )}
            </div>
        );
    }

    onPaypalApprove = (data: any) => {
        this.confirmApproval(data.subscriptionID);
    };
}

const StyledComponent = withStyles(styles)(PaymentRequest) as unknown as FunctionComponent<PaymentRequestProps>;

export default StyledComponent;
