import axios from 'axios'
import {handleError} from "../../components/snackbar/reducer";

const SET_STATUS = 'confirm-email/SET_STATUS'

const initialState = {
    isConfirming: true,
    isSuccess: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_STATUS:
            return {
                ...state,
                isConfirming: action.confirming,
                isSuccess: action.success,
            }

        default:
            return state
    }
}

export const confirmCode = (accountId, code) => {
    return async dispatch => {
        try {
            await axios.post("/api/profile/confirm-email", {
                accountId: accountId,
                code: code,
            })

            dispatch({
                type: SET_STATUS,
                confirming: false,
                success: true,
            })
        } catch (e) {
            dispatch({
                type: SET_STATUS,
                confirming: false,
                success: false,
            })

            handleError(e)(dispatch)
        }
    }
}