import React from 'react';
import { Helmet } from 'react-helmet';
import Loading from '../../components/Loading';
import axios from 'axios';
import { getErrorMessage } from '../../components/snackbar/reducer';

class PaypalScript extends React.Component {
    shouldComponentUpdate(nextProps: any, nextState: any, nextContext: any) {
        return false;
    }

    onLoad = () => {
        try {
            if (!(window as any).paypal) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'onError' does not exist on type 'Readonl... Remove this comment to see the full error message
                const { onError = () => {} } = this.props;
                onError('Paypal Buttons could not be loaded.');
            } else {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'orderId' does not exist on type 'Readonl... Remove this comment to see the full error message
                const { orderId = null, planId = null, onApprove = () => {} } = this.props;

                let buttons;
                if (orderId) {
                    buttons = (window as any).paypal.Buttons({
                        createOrder: () => orderId,
                        onApprove,
                    });
                } else if (planId) {
                    buttons = (window as any).paypal.Buttons({
                        createSubscription: (_: any, actions: any) => {
                            return actions.subscription.create({
                                plan_id: planId,
                                application_context: {
                                    brand_name: 'PRACC.COM',
                                },
                            });
                        },
                        onApprove,
                    });
                }

                if (buttons) {
                    buttons.render((this.props as any).containerRef.current);
                }
            }
        } finally {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'onLoaded' does not exist on type 'Readon... Remove this comment to see the full error message
            const { onLoaded = () => {} } = this.props;
            onLoaded();
        }
    };

    render() {
        const paypalScriptSrc = (this.props as any).src;

        return (
            <Helmet
                onChangeClientState={(newState: any, { scriptTags = [] }, removedTags: any) => {
                    const paypalScript = scriptTags.find((s) => (s as any).src === paypalScriptSrc);

                    if (paypalScript) {
                        (paypalScript as any).onload = this.onLoad;
                    }
                }}
            >
                <script key="paypal-script" src={paypalScriptSrc} type="text/javascript" />
            </Helmet>
        );
    }
}

type PaypalSmartButtonState = any;

export interface PaypalApproveData {
    subscriptionID: string;
}

interface PaypalSmartButtonProps {
    orderId?: string;
    planId?: string;
    onApprove: (data: PaypalApproveData) => void;
}

class PaypalSmartButton extends React.Component<PaypalSmartButtonProps, PaypalSmartButtonState> {
    state = {
        isLoadingSettings: true,
        isSettingsLoaded: false,
        isScriptLoading: true,
        error: null,
        settings: {
            ClientId: '',
            Currency: '',
        },
    };

    containerRef = React.createRef();

    componentDidMount() {
        this.loadSettings();
    }

    async loadSettings() {
        this.setState({ isLoadingSettings: true });

        try {
            const rs = await axios.get('/api/payments/paypal/settings');
            this.setState({ settings: rs.data, isSettingsLoaded: true });
        } catch (e) {
            this.setState({
                error: getErrorMessage(e),
            });
        } finally {
            this.setState({ isLoadingSettings: false });
        }
    }

    getPaypalScriptSrc() {
        return `https://www.paypal.com/sdk/js?client-id=${this.state.settings.ClientId}&currency=${this.state.settings.Currency}&vault=true`;
    }

    render() {
        const { orderId = null, planId = null, onApprove = () => {} } = this.props;

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {this.state.isSettingsLoaded && (
                    <PaypalScript
                        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                        src={this.getPaypalScriptSrc()}
                        orderId={orderId}
                        planId={planId}
                        onApprove={onApprove}
                        onError={(error: any) => this.setState({ error })}
                        onLoaded={() => this.setState({ isScriptLoading: false })}
                        containerRef={this.containerRef}
                    />
                )}

                {(this.state.isScriptLoading || this.state.isScriptLoading || this.state.error) && (
                    <Loading error={this.state.error} size={60} pastDelay />
                )}

                {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<unknown>' is not assignable to typ... Remove this comment to see the full error message */}
                <div ref={this.containerRef} />
            </div>
        );
    }
}

export default PaypalSmartButton;
