import { Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';

interface PraccPaperHeaderProps extends TypographyProps {
    children: React.ReactNode;
}

function PraccPaperHeader({ children, sx = {}, ...rest }: PraccPaperHeaderProps) {
    const theme = useTheme();

    return (
        <Typography
            variant="h4"
            sx={{
                position: 'relative',
                paddingLeft: 3,
                marginBottom: 2,
                '::before': {
                    position: 'absolute',
                    content: '""',
                    borderLeft: `6px solid ${theme.palette.secondary.main}`,
                    top: 2,
                    left: 0,
                    bottom: 2,
                },
                ...sx,
            }}
            {...rest}
        >
            {children}
        </Typography>
    );
}

export default PraccPaperHeader;
