import React from 'react';
import Link from '@mui/material/Link';
import { Modal } from '@mui/material';

interface AimTrainerVideoLinkProps {
    label?: React.ReactNode;
}

interface AimTrainerModalProps {
    open: boolean;
    onClose: () => void;
}

export function AimTrainerModal({ open, onClose }: AimTrainerModalProps) {
    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: '15vh' }}
        >
            <div style={{ position: 'relative', width: `${16 * 3.1}vw`, height: `${9 * 3.1}vw` }}>
                <iframe
                    src="https://iframe.mediadelivery.net/embed/259324/6233379d-6470-42d3-b84f-3378e1b35184?autoplay=false&loop=false&muted=false&preload=true&responsive=true"
                    loading="lazy"
                    style={{ border: 'none', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}
                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                    allowFullScreen
                />
            </div>
        </Modal>
    );
}

function AimTrainerVideoLink({ label }: AimTrainerVideoLinkProps) {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Link
                onClick={(e) => {
                    setOpen(true);
                    e.preventDefault();
                }}
                sx={{ cursor: 'pointer' }}
                underline="hover"
            >
                {label || 'AIM Trainer'}
            </Link>
            <AimTrainerModal open={open} onClose={() => setOpen(false)} />
        </>
    );
}

export default AimTrainerVideoLink;
