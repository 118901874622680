import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { RiMailSendLine as ResendEmailIcon } from 'react-icons/ri';
import styled from 'styled-components';
import BackgroundImage from './img/bg.jpg';
import CircularProgress from '@mui/material/CircularProgress';
import {
    addNotification,
    loadProfile,
    openChat,
    openMsgs,
    resendConfirmationEmail,
    selectTeam,
    selectGame,
    setEmailUnconfirmedHidden,
    setRatingIsOpenHidden,
    setUnreadMsgs,
    showNotifySnack,
    updateIsLoadingNotifications,
    updateProfile,
    updateTeamProfile,
} from './reducer';
import Loadable from 'react-loadable';
import PluginPage from 'containers/static/plugin.js';
import LegalNotice from 'containers/static/legalnotice.js';
import Privacy from 'containers/static/privacy.js';
import ToS from 'containers/static/tos.js';
import Security from 'containers/static/security';
import CustomSnack from 'components/snackbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { Alert, Box, Divider, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as Sentry from '@sentry/browser';
import ProfileAwareRoute from '../../components/ProfileAwareRoute';
import Loading from '../../components/Loading';
import NotFoundPage from '../../components/NotFoundPage';
import { browserHistory } from '../../store';
import { ButtonLink } from '../../components/layout/buttons';
import ButtonWithAsyncAction from '../../components/ButtonWithAsyncAction';
import { isDiscordPreviewTeam } from '../../utils/alpha-testing';
import DiscordPreviewAlert from './discord-preview-alert';
import moment from 'moment/moment';
import { resolveTimeFormatWithMonth } from '../../utils/i18n';
import ProviderLinkAlert from './provider-link-alert';
import GlobalBoostGate from '../../components/boost/GlobalBoostGate';
import RatingDetailsDialog from './rating-details-dialog';
import SideMenu from './SideMenu';
import MobileMenu from './MobileMenu';
import CenteredPage from '../components/CenteredPage';
import ScrollToTop from './ScrollToTop';
import StandaloneMatch from 'containers/matches/standaloneMatch';
import PlayAdsImg from '../../assets/prac_com_ad_color.jpg';
import { GAME_ID_CSGO } from 'utils/games';

const ContentContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    scrollbar-gutter: stable both-edges;
    @media (max-width: 1024px) {
        height: 95vh;
        padding: 0.5rem;
    }
`;

const ContentInner = styled.div`
    width: 1100px;
    display: flex;
    flex-direction: column;
    height: 100%;
    @media (max-width: 1024px) {
        width: 745px;
    }
    @media (max-width: 1350px) {
        width: 90%;
    }
`;

const IndicatorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
`;

const BodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    height: 100vh;
    width: 100vw;
    justify-content: space-between;
    position: relative;
    @media (min-width: 1025px) {
        flex-direction: row;
    }
`;

export const ListView = styled(List)`
    width: 450px;
    height: 93vh;
    overflow-y: scroll;
    @media (max-width: 1024px) {
        width: 100%;
        .MuiTypography-body1 {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 135px;
            white-space: nowrap;
        }
        .MuiListItemSecondaryAction-root {
            top: 25%;
            transform: translateY(-25%);
        }
    }
`;

export const MobileNotificationHeader = styled(Box)`
    background-color: rgb(24, 28, 48);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 5px 10px;
`;

class Prompt extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValue,
        };

        this.onChange = (e) => this._onChange(e);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== this.state.value) {
            this.props.onChange(this.state.value);
        }
    }

    _onChange(e) {
        let value = e.target.value;

        this.setState({ value: value });
    }

    render() {
        return (
            <input
                type="text"
                placeholder={this.props.placeholder}
                className="mm-popup__input"
                value={this.state.value}
                onChange={this.onChange}
            />
        );
    }
}

const AsyncAuthorizeApp = Loadable({
    loader: () => import('containers/app/authorize-app'),
    loading: Loading,
});

const AsyncCompleteAccount = Loadable({
    loader: () => import('containers/app/complete-account'),
    loading: Loading,
});

const AsyncGuideMap = Loadable({
    loader: () => import('containers/app/guide-map'),
    loading: Loading,
});

const AsyncMatches = Loadable({
    loader: () => import('containers/matches'),
    loading: Loading,
});

const AsyncRating = Loadable({
    loader: () => import('containers/rating/index.js'),
    loading: Loading,
});

const AsyncCup = Loadable({
    loader: () => import('containers/cup/index.js'),
    loading: Loading,
});

const AsyncStats = Loadable({
    loader: () => import('containers/stats'),
    loading: Loading,
});

const AsyncHome = Loadable({
    loader: () => import('containers/home'),
    loading: Loading,
});

const AsyncLogin = Loadable({
    loader: () => import('containers/login'),
    loading: Loading,
});

const AsyncQrCode = Loadable({
    loader: () => import('containers/qr-code'),
    loading: Loading,
});

const AsyncGroups = Loadable({
    loader: () => import('containers/groups'),
    loading: Loading,
});

const AsyncTeam = Loadable({
    loader: () => import('containers/team'),
    loading: Loading,
});

const AsyncTeamRequests = Loadable({
    loader: () => import('containers/team/requests'),
    loading: Loading,
});

const AsyncInvite = Loadable({
    loader: () => import('containers/invite'),
    loading: Loading,
});

const AsyncAdmin = Loadable({
    loader: () => import('containers/admin'),
    loading: Loading,
});

const AsyncBillingAdmin = Loadable({
    loader: () => import('containers/admin/billing'),
    loading: Loading,
});

const AsyncChatsAdmin = Loadable({
    loader: () => import('containers/admin/chat'),
    loading: Loading,
});

const AsyncSettings = Loadable({
    loader: () => import('containers/settings'),
    loading: Loading,
});

const AsyncConfirmEmail = Loadable({
    loader: () => import('containers/confirm-email'),
    loading: Loading,
});

const AsyncSearches = Loadable({
    loader: () => import('containers/search'),
    loading: Loading,
});

const AsyncRecorder = Loadable({
    loader: () => import('containers/recorder'),
    loading: Loading,
});

const AsyncReplay = Loadable({
    loader: () => import('containers/replay'),
    loading: Loading,
});

const AsyncBilling = Loadable({
    loader: () => import('containers/billing'),
    loading: Loading,
});

const AsyncRecorderLandingPage = Loadable({
    loader: () => import('containers/recorder/landing-page'),
    loading: Loading,
});

const AsyncScrimLandingPage = Loadable({
    loader: () => import('containers/scrim/landing-page'),
    loading: Loading,
});

const AsyncOptimizerLandingPage = Loadable({
    loader: () => import('containers/optimizer/landing-page'),
    loading: Loading,
});

const AsyncBlogLandingPage = Loadable({
    loader: () => import('containers/blog'),
    loading: Loading,
});

const AsyncDeathmatchPage = Loadable({
    loader: () => import('containers/deathmatch'),
    loading: Loading,
});

const AsyncPlayPage = Loadable({
    loader: () => import('containers/play'),
    loading: Loading,
});

const AsyncStrategyPlayer = Loadable({
    loader: () => import('components/strategy-player/StrategyPlayer'),
    loading: Loading,
});
const AsyncStrategyMapCalibration = Loadable({
    loader: () => import('components/strategy-player/MapCalibration'),
    loading: Loading,
});

const AsyncLoadout = Loadable({
    loader: () => import('containers/settings/cs2/loadout'),
    loading: Loading,
});

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
}));

class App extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    mainContainerRef = null;
    historyUnsubscriber = null;

    constructor(props) {
        super(props);
        this.mainContainerRef = React.createRef();
    }

    state = {
        open: true,
        notification: false,
    };

    globalWarnings = () => {
        const { profile, isResendingConfirmationEmail, isEmailUnconfirmedAlertHidden, setEmailUnconfirmedHidden } =
            this.props;

        const showEmailAlert =
            !isEmailUnconfirmedAlertHidden && profile && !profile.IsEmailConfirmed && profile.HasSetEmail;

        if (showEmailAlert) {
            return (
                <Snackbar
                    open={showEmailAlert}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    sx={{ marginTop: '40px' }}
                >
                    <Alert
                        elevation={5}
                        severity="warning"
                        action={
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={() => setEmailUnconfirmedHidden(true)}
                                size="small"
                            >
                                <CloseIcon />
                            </IconButton>
                        }
                    >
                        <div>
                            Your email{' '}
                            {profile && profile.Email && <span style={{ fontWeight: 'bold' }}>{profile.Email}</span>} is
                            not confirmed yet, please check your inbox.
                        </div>
                        <Box sx={{ marginTop: 1 }}>
                            <ButtonWithAsyncAction
                                variant="contained"
                                disabled={isResendingConfirmationEmail}
                                onClick={() => this.props.resendConfirmationEmail()}
                                startIcon={<ResendEmailIcon />}
                            >
                                Resend Confirmation Email
                            </ButtonWithAsyncAction>
                            {this.props.location.pathname !== '/settings' && (
                                <ButtonLink to="/settings" sx={{ marginLeft: 1 }}>
                                    Change Email
                                </ButtonLink>
                            )}
                        </Box>
                    </Alert>
                </Snackbar>
            );
        }

        const recommendedProviders = ['steam', 'riot', 'discord'];
        const showProviderLinkAlert =
            profile &&
            !recommendedProviders.every((provider) => {
                return profile.Accounts.some((account) => account.Provider === provider);
            });

        if (showProviderLinkAlert) {
            return <ProviderLinkAlert />;
        }

        if (
            profile &&
            profile.Team &&
            !profile.Team.DiscordServerId &&
            profile.Team.CanManageDiscord &&
            isDiscordPreviewTeam(profile, profile.Team)
        ) {
            return <DiscordPreviewAlert />;
        }

        return null;
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    handleOpen = () => {
        this.setState({
            open: true,
        });
    };

    content = () => {
        const { profile } = this.props;

        return (
            <React.Fragment>
                <BodyContainer>
                    <MainContainer id="main-scroll-container" ref={this.mainContainerRef}>
                        <SideMenu />
                        <MobileMenu />

                        {this.globalWarnings()}
                        <ContentContainer>
                            <ContentInner>
                                <CenteredPage>
                                    <Switch>
                                        <Route exact path="/" component={AsyncHome} />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            requireAuth
                                            skipEmailCheck
                                            exact
                                            path="/complete-account"
                                            component={AsyncCompleteAccount}
                                        />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            requireAuth
                                            exact
                                            path="/app/authorize"
                                            component={AsyncAuthorizeApp}
                                        />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            requireAuth
                                            exact
                                            path="/guide-map"
                                            component={AsyncGuideMap}
                                        />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            requireAuth
                                            requireTeam
                                            path="/groups"
                                            key="grouplist"
                                            component={AsyncGroups}
                                        />
                                        <Route
                                            path={'/strategy-player/map-calibration'}
                                            render={() => <AsyncStrategyMapCalibration />}
                                        />
                                        <Route path="/strategy-player" render={() => <AsyncStrategyPlayer />} />
                                        <Route exact path="/login" component={AsyncLogin} />
                                        <Route exact path="/sign-up" component={AsyncLogin} />
                                        <Route exact path="/forgot-password" component={AsyncLogin} />
                                        <Route exact path="/reset-password" component={AsyncLogin} />
                                        <Route exact path="/confirm-email" component={AsyncConfirmEmail} />
                                        <Route exact path="/blog" component={AsyncBlogLandingPage} />
                                        <Route exact path="/blog/:id" component={AsyncBlogLandingPage} />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            requireAuth
                                            exact
                                            path="/qr"
                                            component={AsyncQrCode}
                                        />
                                        <Route exact path="/team-scrim-requests/:id" component={AsyncTeamRequests} />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            requireAuth
                                            path="/team"
                                            component={AsyncTeam}
                                        />
                                        <Route path="/matches/:id" component={StandaloneMatch} />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            requireAuth
                                            requireTeam
                                            path="/matches"
                                            component={AsyncMatches}
                                        />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            requireAuth
                                            requireTeam
                                            path="/rating"
                                            component={AsyncRating}
                                        />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            exact
                                            path="/i/:id"
                                            component={AsyncInvite}
                                        />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            requireAuth
                                            exact
                                            path="/plugin"
                                            component={PluginPage}
                                        />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            requireAuth
                                            exact
                                            path="/billing"
                                            component={AsyncBilling}
                                        />
                                        <Route exact path="/tos" component={ToS} />
                                        <Route exact path="/legalnotice" component={LegalNotice} />
                                        <Route exact path="/privacy" component={Privacy} />
                                        <Route exact path="/platform-security" component={Security} />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            requireAuth
                                            exact
                                            path="/settings/loadout"
                                            component={AsyncLoadout}
                                        />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            requireAuth
                                            requireAdmin
                                            exact
                                            path="/admin/billing"
                                            component={AsyncBillingAdmin}
                                        />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            requireAuth
                                            requireAdmin
                                            path={'/admin/chats'}
                                            component={AsyncChatsAdmin}
                                        />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            requireAuth
                                            requireAdmin
                                            path="/admin"
                                            component={AsyncAdmin}
                                        />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            requireAuth
                                            path="/settings"
                                            component={AsyncSettings}
                                        />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            requireAuth
                                            requireTeam
                                            path="/search"
                                            component={AsyncSearches}
                                        />
                                        <Route exact path="/recorder" component={AsyncRecorderLandingPage} />
                                        <Route exact path="/performance" component={AsyncOptimizerLandingPage} />
                                        <Route exact path="/scrim-search" component={AsyncScrimLandingPage} />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            requireAuth
                                            requireTeam
                                            path="/recorder"
                                            component={AsyncRecorder}
                                        />
                                        <Route path="/replay" component={AsyncReplay} />
                                        <ProfileAwareRoute
                                            profile={profile}
                                            requireAuth
                                            requireTeam
                                            path="/stats"
                                            component={AsyncStats}
                                        />
                                        <Route path="/deathmatch" component={AsyncDeathmatchPage} />
                                        <Route path="/play" component={AsyncPlayPage} />
                                        <Route
                                            path="/free-skins"
                                            exact
                                            component={() => <Redirect to="/play/free-skins" />}
                                        />
                                        <ProfileAwareRoute profile={profile} path="/cup" component={AsyncCup} />
                                        <Route component={NotFoundPage} />
                                    </Switch>
                                </CenteredPage>
                            </ContentInner>
                        </ContentContainer>
                    </MainContainer>
                </BodyContainer>
                <Box style={{ marginTop: '2rem' }} />
            </React.Fragment>
        );
    };

    loading = () => {
        return (
            <IndicatorContainer>
                <CircularProgress size={80} thickness={2} />
            </IndicatorContainer>
        );
    };

    msgDrawer = () => {
        const { isLoadingNotifications } = this.props;

        return (
            <Drawer
                variant="temporary"
                sx={{
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        backgroundColor: 'rgb(12, 17, 38)',
                        backgroundImage: 'radial-gradient(ellipse farthest-side, rgba(0, 111, 255, 0.08), transparent)',
                        backgroundSize: '100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        marginLeft: '15.1rem',
                        '@media (max-width: 600px)': {
                            ml: '0rem',
                        },
                    },
                }}
                anchor="left"
                // open={this.props.msgDrawerOpen}
                onClose={() => {
                    this.props.openMsgs(false);
                }}
            >
                <MobileNotificationHeader
                    sx={{
                        backgroundColor: 'rgb(12, 17, 38)',
                        backgroundImage: 'radial-gradient(ellipse farthest-side, rgba(0, 111, 255, 0.08), transparent)',
                        backgroundSize: '100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                    }}
                    style={{ padding: '0.9rem 10px' }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                borderBottom: '2px solid #F50057',
                            }}
                            variant="h6"
                        >
                            Notifications
                        </Typography>
                    </Box>

                    <IconButton
                        onClick={() => {
                            this.props.openMsgs(false);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </MobileNotificationHeader>
                <div
                    tabIndex={0}
                    role="button"
                    onClick={() => {
                        this.props.openMsgs(false);
                    }}
                    onKeyDown={() => {
                        this.props.openMsgs(false);
                    }}
                >
                    <ListView>
                        {isLoadingNotifications ? (
                            <ListItem>
                                <CircularProgress size={50} />
                            </ListItem>
                        ) : this.props.notifications.length > 0 ? (
                            this.props.notifications.map((n) => {
                                let targetUrl;
                                if (n.MatchID !== null) {
                                    targetUrl = '/matches/' + n.MatchID;
                                } else if (n.OfferID !== null) {
                                    targetUrl = '/search/offers/' + n.OfferID;
                                }

                                let toTeam;
                                if (n.TeamID && this.props.profile) {
                                    toTeam = this.props.profile.Teams.find((team) => team.ID === n.TeamID);
                                    if (!toTeam) {
                                        return null;
                                    }
                                }

                                const styles = targetUrl ? { cursor: 'pointer' } : {};
                                return (
                                    <React.Fragment key={n.id}>
                                        <ListItem
                                            key={n.id}
                                            style={styles}
                                            onClick={() => {
                                                if (targetUrl) {
                                                    if (n.TeamID && this.props.profile.Team.ID !== n.TeamID) {
                                                        this.props.selectTeam(n.TeamID);
                                                    }

                                                    browserHistory.push(targetUrl);
                                                }
                                            }}
                                            secondaryAction={
                                                <React.Fragment>
                                                    <Typography variant="caption">
                                                        {this.props.profile &&
                                                            moment(n.CreatedAt)
                                                                .tz(this.props.profile.Settings.LocalTimezone)
                                                                .fromNow()}
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                        >
                                            <ListItemAvatar>
                                                <Avatar alt="Avatar" src={n.AvatarUrl} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={`${n.What} by ${n.Who}`}
                                                secondary={
                                                    <React.Fragment>
                                                        {this.props.profile
                                                            ? n.MatchTime
                                                                ? moment(n.MatchTime)
                                                                      .tz(this.props.profile.Settings.LocalTimezone)
                                                                      .format(
                                                                          resolveTimeFormatWithMonth(
                                                                              this.props.profile.Settings.TimeFormat
                                                                          )
                                                                      )
                                                                : moment(n.CreatedAt)
                                                                      .tz(this.props.profile.Settings.LocalTimezone)
                                                                      .format(
                                                                          resolveTimeFormatWithMonth(
                                                                              this.props.profile.Settings.TimeFormat
                                                                          )
                                                                      )
                                                            : null}
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        <Divider variant="middle" sx={{ width: '100%', margin: '0px' }} />
                                    </React.Fragment>
                                );
                            })
                        ) : (
                            <ListItem>
                                <ListItemText primary={'No Notifications to show'} />
                            </ListItem>
                        )}
                    </ListView>
                </div>
            </Drawer>
        );
    };

    componentDidCatch(error, errorInfo) {
        if (process.env.NODE_ENV === 'production') {
            const eventId = Sentry.captureException(error);
            Sentry.showReportDialog({
                eventId,
            });
        }
    }

    subscriptions = [];
    isMobile = window.innerWidth <= 767;

    componentDidMount() {
        this.props.loadProfile();
        this.props.websocket.connect();

        // this.isMobile && window.Ybug.hide('launcher');

        this.props.websocket.subscribeConnectionStatus((isConnected) => {
            if (isConnected) {
                this.props.websocket.send('GetWebNotifications');
            }
        });

        this.subscriptions.push(
            this.props.websocket.subscribe((data) => {
                const {
                    showNotifySnack,
                    addNotification,
                    setUnreadMsgs,
                    updateProfile,
                    updateTeamProfile,
                    updateIsLoadingNotifications,
                } = this.props;

                switch (data.MessageType) {
                    case 'UpdateProfile':
                        updateProfile(data.Payload);
                        break;
                    case 'UpdateTeamProfile':
                        updateTeamProfile(data.Payload);
                        break;
                    case 'app.UnreadNotificationCount':
                        setUnreadMsgs(data.Payload.Count);
                        break;
                    case 'app.UpdateWebNotifications':
                        for (const message of data.Payload) {
                            if (
                                message.Description !== '' &&
                                !this.props.notifications.some((n) => n.NotificationID === message.NotificationID)
                            ) {
                                addNotification(message, false);
                            }
                        }
                        updateIsLoadingNotifications();
                        break;
                    case 'app.NewWebNotification':
                        if (data.Payload.Description !== '') {
                            showNotifySnack(data.Payload.Description);
                            addNotification(data.Payload, true);
                        }
                        break;
                }
            })
        );

        this.historyUnsubscriber = this.props.history.listen((newState, updateType) => {
            if (updateType === 'PUSH') {
                this.mainContainerRef.current?.scrollTo(0, 0);
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.profile !== this.props.profile) {
            if (!prevProps.profile || !this.props.profile) {
                this.props.websocket.reconnect();
            } else {
                this.props.websocket.send('RefreshConnInfo');
            }
        }
    }

    componentWillUnmount() {
        this.props.websocket.disconnect();
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
        if (this.historyUnsubscriber) {
            this.historyUnsubscriber();
        }
    }

    render = () => {
        return (
            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',

                    background:
                        'linear-gradient(200.96deg, rgba(4, 20, 38, 0.7) -29.09%, rgba(7, 24, 71, 0.7) 60.77%, rgba(9, 17, 26, 1) 129.35%)',
                    backgroundSize: '400% 400%',
                    backgroundAttachment: 'fixed',

                    // @tag1.1 - remove
                    '::before': {
                        content: '""',
                        position: 'absolute',
                        width: '100vw',
                        height: '100vh',
                        top: 0,
                        left: 0,
                        background: `url(${BackgroundImage}) no-repeat`,
                        backgroundAttachment: 'fixed',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        zIndex: -1,
                    },

                    '::after': {
                        content: '""',

                        position: 'absolute',
                        bottom: 0,
                        left: '-50%',
                        background: 'radial-gradient(ellipse at bottom, rgb(84 100 181 / 30%) 0%, transparent 50%)',
                        width: '200vw',
                        height: '100vh',
                    },

                    '.app-content': {
                        zIndex: 1,
                    },
                    '@media (max-width: 1024px)': {
                        '::after': {
                            width: '100vw',
                        },
                    },
                }}
            >
                <div className="app-content">
                    <ScrollToTop />
                    {this.props.isLoading ? this.loading() : this.content()}
                    <CustomSnack />
                    {this.msgDrawer()}
                </div>
                <GlobalBoostGate />
                <RatingDetailsDialog />
            </Box>
        );
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.app.isLoading,
    isResendingConfirmationEmail: state.app.isResendingConfirmationEmail,
    isEmailUnconfirmedAlertHidden: state.app.isEmailUnconfirmedAlertHidden,
    isRatingOpenAlertHidden: state.app.isRatingOpenAlertHidden,
    msgDrawerOpen: state.app.msgDrawerOpen,
    notifications: state.app.notifications,
    profile: state.app.profile,
    websocket: state.app.websocket,
    isLoadingNotifications: state.app.isLoadingNotifications,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            openChat,
            selectTeam,
            selectGame,
            loadProfile,
            updateProfile,
            updateTeamProfile,
            resendConfirmationEmail,
            setEmailUnconfirmedHidden,
            openMsgs,
            showNotifySnack,
            addNotification,
            setUnreadMsgs,
            setRatingIsOpenHidden,
            updateIsLoadingNotifications,
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
