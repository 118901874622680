import React from 'react';
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { red } from '@mui/material/colors';

interface ConfirmTermsControlProps {
    termsConfirmed: boolean;
    showTermsError: boolean;
    onChange: (termsConfirmed: boolean) => void;
}

function ConfirmTermsControl({ termsConfirmed, showTermsError, onChange }: ConfirmTermsControlProps) {
    return (
        <FormControlLabel
            onChange={(_, isTermsConfirmed) => {
                onChange(isTermsConfirmed);
            }}
            control={
                <Tooltip
                    title="Please accept the terms of service, and the privacy policy."
                    open={showTermsError}
                    arrow
                    placement="top"
                    style={showTermsError ? { backgroundColor: red[400] } : {}}
                >
                    <Checkbox color="primary" checked={termsConfirmed} />
                </Tooltip>
            }
            label="I agree to the terms of service, and the privacy policy."
        />
    );
}

export default ConfirmTermsControl;
