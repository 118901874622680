import React from 'react';
import { useAds } from './AdContextProvider';
import axios from "axios";

const publisherId = '1024993';
const websiteId = '74331';
const rampScriptSrc = `https://cdn.intergient.com/${publisherId}/${websiteId}/ramp.js`;

const rampWindow = window as any;
rampWindow.ramp = rampWindow.ramp || {};
rampWindow.ramp.que = rampWindow.ramp.que || [];
rampWindow.ramp.passiveMode = true;

export type RampApi = {
    addUnits: (units: Array<{ type: string; selectorId?: string }>) => Promise<void>;
    displayUnits: () => void;
    destroyUnits: (units: 'all' | Array<string>) => void;
    setPath: (path: string) => void;
    triggerRefresh: () => void;
    showCmpModal: () => void;
    onPlayerReady: () => void;
    showRewardedVideo: (settings: { code: string; userId: string; callback: (res: any) => void }) => void;
    closeRewardedVideo: () => void;
};

let isAdded = false;

export function rampQueue(f: () => void) {
    rampWindow.ramp.que.push(f);
}

export function getRamp() {
    return rampWindow.ramp as RampApi;
}

function Playwire() {
    const { setAdsBlocked } = useAds();

    React.useEffect(() => {
        if (isAdded) {
            return;
        }

        const configScript = document.createElement('script');
        configScript.src = rampScriptSrc;
        document.head.appendChild(configScript);

        isAdded = true;
    }, []);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setAdsBlocked(true);
            axios.post('/api/profile/feedback', {
                type: 'ads.blocked',
            })
        }, 10000);

        rampQueue(() => {
            const ramp = getRamp();

            // see https://support.playwire.com/v1/docs/ad-units-array-for-ads-api
            ramp.destroyUnits([
                'all',
                'leaderboard_atf',
                'leaderboard_btf',
                'sky_atf',
                'sky_btf',
                'trendi_slideshow',
                'trendi_video',
                'site_skin',
                'flex_leaderboard',
                'top_rail',
                'bottom_rail',
            ]);

            ramp.addUnits([
                { type: 'trendi_slideshow' },
                { type: 'trendi_video' },
                { type: 'site_skin' },
                { type: 'flex_leaderboard' },
                { type: 'top_rail' },
                //                    { type: 'right_rail' },
                { type: 'bottom_rail' },
                //                    { type: 'left_rail' },
            ]).finally(() => {
                clearTimeout(timeout);
                setAdsBlocked(false);
                axios.post('/api/profile/feedback', {
                    type: 'ads.loaded',
                })
                ramp.displayUnits();
            });
        });

        return () => {
            clearTimeout(timeout);

            rampQueue(() => {
                getRamp().destroyUnits([
                    'all',
                    'leaderboard_atf',
                    'leaderboard_btf',
                    'sky_atf',
                    'sky_btf',
                    'trendi_slideshow',
                    'trendi_video',
                    'site_skin',
                    'flex_leaderboard',
                    'top_rail',
                    'bottom_rail',
                ]);
            });
        };
    }, []);

    return null;
}

export default Playwire;
