import React from 'react';
import { AccountProvider, ProfileAccount } from '../../model/profile';
import { Box, Button, Typography } from '@mui/material';
import AccountProviderIcon from './AccountProviderIcon';
import AccountLoginButton from './AccountLoginButton';
import { getProviderDisplayName } from './utils';
import DeleteButton from '../DeleteButton';
import axios from 'axios';
import Icon from '../icon';
import { queueMessage } from '../snackbar/reducer';
import { useDispatch } from 'react-redux';
import Link from '@mui/material/Link';
import SetTradeUrlLink from './SetTradeUrlLink';

interface LinkedAccountRowProps {
    provider: AccountProvider;
    account: ProfileAccount | null;
    size?: 'small' | null;
}

function LinkedAccountRow({ provider, account, size }: LinkedAccountRowProps) {
    const dispatch = useDispatch();

    const isSmallSize = size === 'small';
    const maxIdLength = 25; // Adjust the length as needed

    function renderContent() {
        if (!account) {
            return (
                <AccountLoginButton
                    variant="contained"
                    provider={provider}
                    sx={isSmallSize ? { marginLeft: 2, fontSize: '0.7rem', padding: '3px 16px' } : { marginLeft: 2 }}
                    onSuccess={() => {
                        queueMessage('success', 'Your account was linked successfully.')(dispatch);
                    }}
                >
                    Link {getProviderDisplayName(provider)} Account
                </AccountLoginButton>
            );
        }

        const truncatedId =
            account.ThirdPartyId.length > maxIdLength
                ? `${account.ThirdPartyId.substring(0, maxIdLength)}...`
                : account.ThirdPartyId;

        console.log('Accoudnt', account);

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 2 }}>
                <Typography
                    variant="subtitle2"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        '@media (max-width: 1024px)': { flexDirection: 'column', alignItems: 'flex-start' },
                    }}
                >
                    {account.DisplayName}
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={{
                            marginLeft: 1,
                            display: 'flex',
                            alignItems: 'center',
                            '@media (max-width: 1024px)': { marginLeft: 0 },
                        }}
                    >
                        (ID:{' '}
                        <Box
                            component="span"
                            sx={{
                                display: 'inline-block',
                                maxWidth: 300,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                marginLeft: 0.5,
                            }}
                        >
                            {truncatedId}
                        </Box>
                        )
                    </Typography>
                </Typography>
                {provider === 'steam' && (
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '@media (max-width: 1024px)': { flexDirection: 'column', alignItems: 'flex-start' },
                        }}
                    >
                        Trade-URL
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            sx={{
                                marginLeft: 1,
                                display: 'flex',
                                alignItems: 'center',
                                '@media (max-width: 1024px)': { marginLeft: 0 },
                            }}
                        >
                            <Box component="span" sx={{ fontStyle: 'oblique', marginRight: 1.5 }}>
                                {account.HasTradeURL ? 'set' : 'not set'}
                            </Box>

                            <SetTradeUrlLink />
                        </Typography>
                    </Typography>
                )}

                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 0.5 }}>
                    <AccountLoginButton
                        variant="contained"
                        provider={provider}
                        sx={isSmallSize ? { fontSize: '0.7rem', padding: '3px 16px' } : null}
                        startIcon={<Icon>refresh</Icon>}
                        dialogTitle={`Refresh ${getProviderDisplayName(provider)} Account`}
                        onSuccess={() => {
                            queueMessage('success', 'Your account was refreshed successfully.')(dispatch);
                        }}
                    >
                        Refresh
                    </AccountLoginButton>
                    <DeleteButton
                        sx={
                            isSmallSize ? { marginLeft: 1, fontSize: '0.7rem', padding: '3px 16px' } : { marginLeft: 1 }
                        }
                        targetLabel={`${getProviderDisplayName(provider)} Account`}
                        onDelete={async () => {
                            await axios.delete(`/api/profile/accounts/${provider}`);
                        }}
                    >
                        Remove
                    </DeleteButton>
                </Box>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <AccountProviderIcon size={isSmallSize ? 20 : 40} provider={provider} />
            {renderContent()}
        </Box>
    );
}

export default LinkedAccountRow;
