import {
    GAME_ID_CSGO,
    GAME_ID_DOTA2,
    GAME_ID_HALO_INFINITE,
    GAME_ID_LOL,
    GAME_ID_LOL_WILD_RIFT,
    GAME_ID_RB6,
    GAME_ID_VALORANT,
    GAME_ID_ROCKET_LEAGUE,
    GAME_ID_DEADLOCK,
    GAME_ID_SPECTRE_DIVIDE,
} from './games';

const csgoRegions = [
    { key: 'Europe (CET / UTC+1)', label: 'Europe' },
    { key: 'North America (PST / UTC-8)', label: 'North America' },
    { key: 'South America (AST / UTC-4)', label: 'South America' },
    { key: 'Asia (AWST / UTC+8)', label: 'Asia' },
    { key: 'Middle East / CIS (ORAT / UTC+5)', label: 'Middle East/CIS' },
];

const spectreDivideRegions = [
    { key: 'Europe (CET / UTC+1)', label: 'Europe' },
    { key: 'North America (PST / UTC-8)', label: 'North America' },
    { key: 'South America (AST / UTC-4)', label: 'South America' },
    { key: 'Asia (AWST / UTC+8)', label: 'Asia' },
    { key: 'Middle East / CIS (ORAT / UTC+5)', label: 'Middle East/CIS' },
];

const haloInfiniteRegions = [
    { key: 'Europe (CET / UTC+1)', label: 'Europe' },
    { key: 'North America (PST / UTC-8)', label: 'North America' },
    { key: 'South America (AST / UTC-4)', label: 'South America' },
    { key: 'Asia (AWST / UTC+8)', label: 'Asia' },
    { key: 'Middle East / CIS (ORAT / UTC+5)', label: 'Middle East/CIS' },
];

const dotaRegions = [
    { key: 'eu', label: 'Europe' },
    { key: 'na', label: 'North America' },
    { key: 'sea', label: 'Southeast Asia' },
    { key: 'sa', label: 'South America' },
    { key: 'ru', label: 'Russia' },
    { key: 'oc', label: 'Oceania' },
    { key: 'nas', label: 'North Asia (KR,CN,JP)' },
    { key: 'nas', label: 'South Asia (OCE,SA,SEA)' },
];

const deadlockRegions = [
    { key: 'eu', label: 'Europe' },
    { key: 'na', label: 'North America' },
    { key: 'sea', label: 'Southeast Asia' },
    { key: 'sa', label: 'South America' },
    { key: 'ru', label: 'Russia' },
    { key: 'oc', label: 'Oceania' },
    { key: 'nas', label: 'North Asia (KR,CN,JP)' },
    { key: 'nas', label: 'South Asia (OCE,SA,SEA)' },
];

const lolRegions = [
    { key: 'eu-we', label: 'EU West' },
    { key: 'br', label: 'Brazil' },
    { key: 'eu-ne', label: 'EU Nordic and East' },
    { key: 'jp', label: 'Japan' },
    { key: 'la-n', label: 'Latin America North' },
    { key: 'la-s', label: 'Latin America South' },
    { key: 'na', label: 'North America' },
    { key: 'oc', label: 'Oceania' },
    { key: 'ru', label: 'Russia' },
    { key: 'tr', label: 'Turkey' },
];

const rb6Regions = [
    { key: 'eu', label: 'Europe' },
    { key: 'na', label: 'North America' },
    { key: 'sa', label: 'South America' },
    { key: 'as', label: 'Asia' },
    { key: 'me', label: 'Middle East/CIS' },
];

const valorantRegions = [
    { key: 'eu', label: 'Europe' },
    { key: 'na', label: 'North America' },
    { key: 'sa', label: 'South America' },
    { key: 'nas', label: 'North Asia (KR,CN,JP)' },
    { key: 'sas', label: 'South Asia (OCE,SA,SEA)' },
];

const rocketLeagueRegions = [
    { key: 'Europe (CET / UTC+1)', label: 'Europe' },
    { key: 'North America (PST / UTC-8)', label: 'North America' },
    { key: 'South America (AST / UTC-4)', label: 'South America' },
    { key: 'Asia (AWST / UTC+8)', label: 'Asia' },
    { key: 'Middle East / CIS (ORAT / UTC+5)', label: 'Middle East/CIS' },
];

export function getRegionsForProfile(profile) {
    if (!profile || !profile.Team) {
        return [];
    }

    return getRegionsForGameId(profile.Team.GameID);
}

export function getDefaultRegionForProfile(profile) {
    const regions = getRegionsForProfile(profile);

    return regions.length > 0 ? regions[0].key : undefined;
}

export function getRegionsForGameId(gameId) {
    switch (gameId) {
        case GAME_ID_CSGO:
            return csgoRegions;
        case GAME_ID_DOTA2:
            return dotaRegions;
        case GAME_ID_LOL:
            return lolRegions;
        case GAME_ID_RB6:
            return rb6Regions;
        case GAME_ID_VALORANT:
            return valorantRegions;
        case GAME_ID_LOL_WILD_RIFT:
            return lolRegions;
        case GAME_ID_HALO_INFINITE:
            return haloInfiniteRegions;
        case GAME_ID_ROCKET_LEAGUE:
            return rocketLeagueRegions;
        case GAME_ID_DEADLOCK:
            return deadlockRegions;
        case GAME_ID_SPECTRE_DIVIDE:
            return spectreDivideRegions;
        default:
            return [];
    }
}
