import React from 'react';
import { BoostFeature } from '../../../../model/boost';
import { Box, capitalize, Chip, CircularProgress, Typography } from '@mui/material';
import BoostProgress from './BoostProgress';
import { OverridableStringUnion } from '@mui/types';
import { ChipPropsColorOverrides } from '@mui/material/Chip/Chip';
import BoostLevel from '../../BoostLevel';
import BoostLevelProgress from '../../../../components/boost/BoostLevelProgress';

const tagColors: Record<
    string,
    OverridableStringUnion<
        'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
        ChipPropsColorOverrides
    >
> = {
    calendar: 'warning',
    team: 'success',
    search: 'primary',
};

interface BoostFeatureSummaryProps {
    feature: BoostFeature;
    boostCount: number;
}

function BoostFeatureSummary({ feature, boostCount }: BoostFeatureSummaryProps) {
    const level = React.useMemo(() => {
        let lastLevel = 0;
        for (const level of feature.Levels) {
            if (level.TotalNumBoosts > boostCount) {
                return lastLevel;
            }

            lastLevel++;
        }

        return lastLevel;
    }, [feature, boostCount]);

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>
                    <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
                        Unlock {feature.Title}
                    </Typography>
                    <Box sx={{ marginBottom: 1 }}>
                        {feature.Tags.map((t) => (
                            <Chip color={tagColors[t] || 'warning'} key={t} label={capitalize(t)} size="small" />
                        ))}
                    </Box>
                </div>
                <div>
                    <BoostLevelProgress level={level} maxLevel={feature.Levels.length} />
                </div>
            </Box>
            <Typography variant="body1" color="textSecondary">
                {feature.Description}
            </Typography>
        </div>
    );
}

export default BoostFeatureSummary;
