import { ProductTrial, Subscription } from '../../model/subscription';
import { Amount, indexedProducts, Plan, Product } from '../../model/products';
import { Profile, ProfileGroup, ProfileTeam } from '../../model/profile';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useActivePlan } from '../../utils/billing';

export function computePlanMonthlyAmount(
    plan: Plan,
    seatCount: number = 1,
    addons: Record<string, string> = {}
): Amount {
    let monthlyPrice = plan.amount.value;
    if (plan.billingInterval) {
        monthlyPrice /= plan.billingInterval.intervalCount;
    }
    if (seatCount) {
        monthlyPrice *= seatCount;
    }
    if (addons && plan.addons) {
        for (const addonKey in addons) {
            if (!Object.prototype.hasOwnProperty.call(addons, addonKey)) {
                continue;
            }

            const addon = plan.addons.find((a) => a.type === addonKey);
            if (addon) {
                const addonValue: string = addons[addonKey];
                if (addonValue in addon.priceOptions) {
                    const amount = addon.priceOptions[addonValue].amount.value;
                    if (addon.perSeat && seatCount) {
                        monthlyPrice += amount * seatCount;
                    } else {
                        monthlyPrice += amount;
                    }
                }
            }
        }
    }

    return {
        value: monthlyPrice,
        currency: plan.amount.currency,
    };
}

export function isPlanActive(subscription: Subscription | null, product: Product, plan: Plan): boolean {
    if (!subscription) {
        return false;
    }

    return subscription.Items[product.key] && subscription.Items[product.key].plan === plan.key;
}

export function getCurrentPlan(subscription: Subscription | null, product: Product): Plan | null {
    if (subscription) {
        //check if items contains product
        if (!subscription.Items[product.key]) {
            return null;
        }
        const currentPlanKey = subscription.Items[product.key].plan;

        return indexedProducts[product.key].plans.find((p: any) => p.key === currentPlanKey) || null;
    }

    return null;
}

interface CompareResult {
    isDowngrade: boolean;
    isCancel: boolean;
}

export function comparePlans(currentPlan: Plan, newPlan: Plan): CompareResult {
    let isDowngrade = false;
    let isCancel = false;
    if (currentPlan.amount.value >= newPlan.amount.value) {
        isDowngrade = true;

        if (currentPlan.key === newPlan.key) {
            isCancel = true;
        }
    }

    return { isDowngrade, isCancel };
}

export function getTrial(entity: string, product: Product, team: ProfileTeam | null | undefined): ProductTrial | null {
    if (entity === 'org') {
        if (team && team.ProductTrials) {
            return team.ProductTrials[product.key] || null;
        }
    }

    return null;
}

export function useProUser() {
    return (useSelector((state: any) => state.app.profile?.IsProUser) as boolean | undefined) ?? false;
}

export function useVipUser(): boolean {
    const playPlan = useActivePlan('deathmatch');

    return !!playPlan;
}

export function getAvailablePlansFilter(
    entity: string,
    product: Product,
    activeSubscription: Subscription | null,
    selectedTeam: ProfileTeam | null | undefined,
    isProUser: boolean | undefined,
    region: string | undefined
): (p: Plan) => boolean {
    function basicFilter(p: Plan) {
        const isActive = activeSubscription?.Items[product.key]?.plan === p.key;

        if (p.inactive) {
            if (!isActive) {
                return false;
            }
        }
        if (!isActive && p.region && p.region !== region) {
            return false;
        }

        const isApproved = selectedTeam?.BillingDetails?.ApprovedPlans?.includes(`${product.key}:${p.key}`);
        if (!isApproved) {
            if (p.requiresApproval) {
                return false;
            }

            if (p.onlyForPros || p.onlyForAmateurs) {
                if (isProUser) {
                    if (p.onlyForAmateurs) {
                        return false;
                    }
                } else {
                    if (p.onlyForPros) {
                        return false;
                    }
                }
            }
        }

        if (entity === 'me') {
            return !p.requiresOrganization && !p.requiresTeam;
        }

        return !!p.requiresOrganization || !!p.requiresTeam;
    }

    return (p: Plan) => {
        if (!basicFilter(p)) {
            return false;
        }
        if (p.onlyFallback) {
            const hasOtherPlanAccess = product.plans.some((plan) => {
                if (plan.key === p.key) {
                    return false;
                }

                return basicFilter(plan);
            });
            if (hasOtherPlanAccess) {
                return false;
            }
        }

        return true;
    };
}

export function isTrialActive(trial: ProductTrial): boolean {
    return moment(trial.EndsAt).isAfter(moment.now());
}
