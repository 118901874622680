import axios from 'axios'
import {QUEUE_MESSAGE} from 'components/snackbar/reducer'
import {handleError} from "../../components/snackbar/reducer";

export const LOAD_REQUESTED = 'matches/LOAD_REQUESTED'
export const LOAD = 'matches/LOAD'

export const CONFIRM_DELETE_MATCH = 'matches/CONFIRM_DELETE_MATCH'
export const UPDATE_EXTERNAL_EVENT = 'matches/UPDATE_EXTERNAL_EVENT'
export const DELETE_EXTERNAL_EVENT = 'matches/DELETE_EXTERNAL_EVENT'

export const UPDATE_SETTINGS = 'matches/UPDATE_SETTINGS'

const initialState = {
    isLoading: true,
    showCancelledMatches: false,
    showPastMatches: false,
    matches: [],
    externalEvents: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_REQUESTED:
            return {
                ...state,
                isLoading: true
            }

        case LOAD:
            return {
                ...state,
                isLoading: false,
                matches: action.Matches,
                externalEvents: action.Events,
            }

        case CONFIRM_DELETE_MATCH:
            return {
                ...state,
                matches: state.matches.filter(match => match.ID !== action.id),
            }

        case UPDATE_EXTERNAL_EVENT:
            return {
                ...state,
                externalEvents: [
                    action.event,
                    ...state.externalEvents.filter(event => event.ID !== action.event.ID),
                ]
            }

        case DELETE_EXTERNAL_EVENT:
            return {
                ...state,
                externalEvents: state.externalEvents.filter(event => event.ID !== action.id)
            }

        case UPDATE_SETTINGS:
            return {
                ...state,
                ...action.newSettings
            }

        default:
            return state
    }
}

export const load = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: LOAD_REQUESTED
        })

        try {
            const state = getState()
            const res = await axios.get('/api/matches', {
                params: {
                    cancelled: state.matches.showCancelledMatches ? 'true' : 'false',
                    past: state.matches.showPastMatches ? 'true' : 'false',
                },
                headers: {
                    Accept: 'application/vnd.pracc.v1+json',
                }
            })

            dispatch({
                type: LOAD,
                ...res.data
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const confirmDeleteMatch = (id, reason, message) => {
    return async dispatch => {
        try {
            await axios.post('/api/matches/delete', {
                id,
                reason,
                message,
            })
            dispatch({
                type: CONFIRM_DELETE_MATCH,
                id
            })
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Deleted Match!',
                variant: 'success'
            })

            return true
        } catch (e) {
            handleError(e)(dispatch)

            return false
        }
    }
}

export const saveNewItem = item => {
    return async dispatch => {
        try {
            const res = await axios.post('/api/matches/create',
                {
                    Time: item.start,
                    End: item.end,
                    Description: item.description,
                    Title: item.title,
                    Color: item.color
                },
                {
                    headers: {
                        Accept: 'application/vnd.pracc.v1+json',
                    }
                }
            )

            dispatch({
                type: UPDATE_EXTERNAL_EVENT,
                event: res.data,
            })

            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Saved new Calendar Item!',
                variant: 'success'
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const saveItem = (id, item) => {
    return async dispatch => {
        try {
            const res = await axios.post('/api/matches/update',
                {
                    ID: id,
                    Time: item.start,
                    End: item.end,
                    Description: item.description,
                    Title: item.title,
                    Color: item.color
                },
                {
                    headers: {
                        Accept: 'application/vnd.pracc.v1+json',
                    }
                }
            )

            dispatch({
                type: UPDATE_EXTERNAL_EVENT,
                event: res.data,
            })

            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Saved Calendar Item!',
                variant: 'success'
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const deleteItem = (id) => {
    return async dispatch => {
        try {
            await axios.get('/api/matches/deleteItem/' + id)

            dispatch({
                type: DELETE_EXTERNAL_EVENT,
                id,
            })

            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Deleted Calendar Item!',
                variant: 'success'
            })
        } catch (e) {
            handleError(e)(dispatch)
        }
    }
}

export const updateSettings = newSettings => {
    return dispatch => dispatch({
        type: UPDATE_SETTINGS,
        newSettings,
    })
}